<template>
    <div class="inline-block" :name="instanceId">
      <section v-if="isListMode" class="is-flex">
        <span class="list-icon clickable" :title="listIconTitle" @click.stop.prevent="onLaunchStatusChangeModal()">
          <i class="fal fa-thumbs-up fa" :id="instanceId" :class="getInventoryListRequestIconColour()"></i>
        </span>
      </section>
      <div v-else class="quick-action">
        <button
          class="button"
          :id="instanceId"
          @click.stop.prevent="onLaunchStatusChangeModal()"
          :title="listIconTitle"
          :class="getInventoryRequestIconColour()"
          :disabled="!canShowCommissionModifyButton">
          <b-icon pack="fal" icon="thumbs-up" custom-size="fa-lg"></b-icon>
        </button>
      </div>
      <portal to="global-modal-portal" v-if="confirmStatusChangeActive">
        <b-modal :active.sync="confirmStatusChangeActive" :width="640" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Change commission request status</p>
            </header>
            <section class="modal-card-body" style="padding:12px">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
              <form class="view-container">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="field">
                      <label class="label">{{ purchaseCommissionTitle }}</label>
                      <div class="control has-icons-left">
                        <money name="commission" :disabled="true" data-vv-as="purchase commission" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('commission') }" type="text" placeholder="0.00" v-model="commissionRequest.commission" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('commission')" class="help is-danger"><i v-show="errors.has('commission')" class="fas fa-exclamation-triangle"></i> {{ errors.first('commission') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="field">
                      <label class="label">Commission Source</label>
                      <input name="commissionSource" data-vv-as="commission source"  v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('commissionSource') }" type="text" placeholder="" v-model="commissionRequest.commissionToName" :readonly="true">
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="field">
                      <label class="label">Commission Requested By</label>
                      <input name="commissionRequestedBy" data-vv-as="commission source"  v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('commissionRequestedBy') }" type="text" placeholder="" v-model="commissionRequest.commissionRequestedBy" :readonly="true">
                    </div>
                  </div>
                  <div class="column is-12">
                    <button-list-selector
                    validateAs="commission request status"
                    label="Commission request status"
                    :required="true"
                    v-model="selectedCommissionRequestStatus"
                    :allowDeselection="false"
                    :availableValues="filteredInventoryCommissionRequestStatusTypes"
                    :enabled="true">
                    </button-list-selector>
                  </div>
                </div>
              </form>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmStatusChange(true)" type="button" :disabled="!canSave">Save</button>
              <button class="button is-danger" @click="onConfirmStatusChange(false)" type="button">Cancel</button>
            </footer>
          </div>
        </b-modal>
      </portal>
    </div>
  </template>

<script>
import eventBus from '@/eventBus'
import formattingMixin from '@/mixins/accounting/formatting'
import inventoryService from '@/services/inventoryService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import ButtonListSelector from '@/components/generic/ButtonListSelector'

export default {
  name: 'InventoryCommissionProcessingAction',
  mixins: [formattingMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'button-list-selector': ButtonListSelector
  },
  props: {
    commissionRequest: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: true
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    saveStatusChange: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isSaving: false,
      isLoading: false,
      serverErrors: [],
      serverErrorMessage: '',
      confirmStatusChangeActive: false,
      selectedCommissionRequestStatus: null
    }
  },
  computed: {
    hasCommissionRequestModifyAccess: function () {
      return this.hasFeatureAccess('inventory.commission.approve.reject')
    },
    listIconTitle: function () {
      if (this.commissionRequest) {
        if (this.commissionRequest.commissionRequestStatus === 'Requested') {
          return 'Commission approval requested, click to update commission request status.'
        }
      }
      return 'Click to update commission request status.'
    },
    canShowCommissionModifyButton: function () {
      if (!this.hasCommissionRequestModifyAccess) return false
      return true
    },
    filteredInventoryCommissionRequestStatusTypes: function () {
      let excludedTypes = [3, 4]
      let filteredTypes = this.definedTypes.inventoryCommissionRequestStatusTypes.filter(function (item) {
        return excludedTypes.indexOf(item.id) === -1
      })
      return filteredTypes
    },
    purchaseCommissionTitle: function () {
      if (this.commissionRequest) {
        if (this.commissionRequest.commissionRequestStatus === 'Requested') {
          return 'Requested Purchase Commission'
        }
      }
      return 'Purchase Commission'
    },
    canSave: function () {
      if (this.selectedCommissionRequestStatus && this.commissionRequest && this.selectedCommissionRequestStatus.altName === this.commissionRequest.commissionRequestStatus) {
        return false
      }
      return true
    }
  },
  methods: {
    onConfirmStatusChange: function (save) {
      if (save) {
        if (this.saveStatusChange) {
          this.isSaving = true
          this.serverErrorMessage = ''
          this.serverErrors = []
          let that = this
          inventoryService.updateCommissionRequestStatus(that.commissionRequest.inventoryId, that.commissionRequest.id, that.selectedCommissionRequestStatus.altName).then(response => {
            that.isSaving = false
            if (response.errors) {
              that.serverErrors = response.errors
            } else {
              eventBus.$emit('quickview-refresh')
              eventBus.$emit('reload-payment-tracking-list')
            }
          }).catch((error) => {
            that.serverErrorMessage = error.message
            that.isSaving = false
          })
        } else {
          this.$emit('commission-request-status-change', this.selectedCommissionRequestStatus)
        }
      }
      this.confirmStatusChangeActive = false
    },
    onLaunchStatusChangeModal: function () {
      if (this.hasCommissionRequestModifyAccess) {
        this.selectedCommissionRequestStatus = this.commissionRequest && this.commissionRequest.commissionRequestStatus ? this.definedTypes.inventoryCommissionRequestStatusTypes.find(x => x.altName === this.commissionRequest.commissionRequestStatus) : null
        this.confirmStatusChangeActive = true
      }
    },
    getInventoryRequestIconColour: function () {
      if (this.commissionRequest) {
        if (this.commissionRequest.commissionRequestStatus === 'Requested') {
          return 'is-warning'
        } else if (this.commissionRequest.commissionRequestStatus === 'Approved') {
          return 'is-success'
        } else if (this.commissionRequest.commissionRequestStatus === 'Rejected') {
          return 'is-danger'
        }
      }
      return ''
    },
    getInventoryListRequestIconColour: function () {
      if (this.hasCommissionRequestModifyAccess) {
        if (this.commissionRequest) {
          if (this.commissionRequest.commissionRequestStatus === 'Requested') {
            return 'has-text-warning'
          } else if (this.commissionRequest.commissionRequestStatus === 'Approved') {
            return 'has-text-success'
          } else if (this.commissionRequest.commissionRequestStatus === 'Rejected') {
            return 'has-text-danger'
          }
        }
      } else {
        return 'has-text-grey'
      }
      return ''
    }
  },
  watch: {
  },
  mounted: function () {
  },
  beforeDestroy () {
  },
  updated: function () {
  }
}

</script>

  <style scoped>
    .modal-card-head, .modal-card-foot {
      padding: 20px !important;
    }

    .modal-card {
      overflow: auto;
      min-width: 95% !important;
      max-width: 95% !important;
      width: 95% !important;
    }

  </style>
