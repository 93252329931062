<template>
  <div class="inline-block" :name="instanceId">

    <div v-if="showActionButton">
      <section v-if="isListMode" class="is-flex">
        <span class="list-icon clickable" :title="listIconTitle" @click.stop.prevent="onLaunchConfirmPaidDeposit()" v-if="canShowMarkDepositPaidControl">
          <i class="fal fa-money-check-alt fa-lg has-text-link" :id="instanceId"></i>
        </span>
      </section>
      <div v-else-if="isMarkPaidFromNotYetPaidMode">
        <button v-if="canShowMarkDepositPaidControl" class="button is-primary" :class="{'is-loading': isSaving }" @click.stop.prevent="onLaunchConfirmPaidDeposit()" type="button">Mark As Paid</button>
      </div>
      <div class="quick-action" v-else>
        <button class="button" :id="instanceId" @click.stop.prevent="onLaunchConfirmPaidDeposit()" :title="listIconTitle" v-if="canShowMarkDepositPaidControl">
          <b-icon pack="fal" icon="money-check-alt" class="has-text-link" custom-size="fa-lg"></b-icon>
        </button>
      </div>
    </div>

    <portal to="global-modal-portal" v-if="confirmProcessedInCdkActive" :disabled="!usePortal">
      <b-modal :key="instanceId" :active.sync="confirmProcessedInCdkActive" scroll="keep" :has-modal-card="true" :canCancel="false" id="deposit-cdk-modal">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">Confirm Deposit Processed In CDK</p>
          </header>
            <section class="modal-card-body view-container">
              <div class="columns is-multiline is-mobile">
                <div class="column is-12">
                  <div class="is-flex notification is-warning">
                    <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
                    <span class="ml-10">
                        Confirm that this deposit been receipted in CDK?
                        <br /><br />Clicking Yes will mark this deposit as paid.
                    </span>
                  </div>
                </div>
                <div class="column is-12 is-narrow">
                  <payment-conversion-control
                    :key="'pccm' + paymentTrackingId"
                    :paymentTracking="paymentTracking"
                    :confirmProcessedInCdkActive.sync="confirmProcessedInCdkActive">
                  </payment-conversion-control>
                </div>
                <div class="column is-6">
                  <div class="notification">
                    <div class="tags has-addons">
                      <span class="tag is-dark">Name</span>
                      <span class="tag is-primary">{{ paymentTracking.customerName }}</span>
                    </div>
                    <div class="tags has-addons">
                      <span class="tag is-dark">Amount</span>
                      <span class="tag is-primary">{{ paymentTracking.amount | currency('$', 2) }}</span>
                    </div>
                    <div class="tags has-addons" v-if="paymentTracking.stockNo">
                      <span class="tag is-dark">StockNo</span>
                      <a @click.stop.prevent="onStockNoClick(paymentTracking, true)">
                        <span class="tag is-primary">{{ paymentTracking.stockNo }}</span>
                      </a>
                    </div>
                    <div class="tags has-addons" v-if="paymentTracking.sqInvoiceUrl">
                      <span class="tag is-dark">Invoice</span>
                      <span class="tag is-primary"><a class="ml-5 mt-5" v-if="paymentTracking.sqInvoiceUrl" @click.stop.prevent="downloadInvoice()" v-tooltip="{ content: 'Click to view invoice' }">
                        <i class="grid-icon fa-fw fal fa-paperclip fa-lg" :class="getColourClassByPaymentTrackingType(paymentTracking.paymentStatus)"></i>
                      </a></span>
                    </div>
                    <div class="tags has-addons" v-if="paymentTracking.sqReceiptUrl || paymentTracking.latestSqReceiptUrl">
                      <span class="tag is-dark">Receipt</span>
                      <span class="tag is-primary"><a class="ml-5 mt-5" v-if="paymentTracking.sqReceiptUrl" @click.stop.prevent="onDownloadSquareReceipt()" v-tooltip="{ content: 'Click to view receipt' }">
                        <i class="grid-icon fa-fw fal fa-receipt fa-lg" :class="getColourClassByPaymentTrackingType(paymentTracking.paymentStatus)"></i>
                      </a></span>
                    </div>
                    <div class="tags has-addons" v-if="paymentTracking && paymentTracking.roNumber">
                      <span class="tag is-dark">R.O. Number</span>
                      <span class="tag is-warning">{{ paymentTracking.roNumber }}</span>
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="hasSignedDocs">
                  <div class="field">
                    <label class="label">{{signedDocLabel}}</label>
                    <div class="field">
                      <file-selector
                        :key="signedDocs.id"
                        v-if="hasSignedDocs"
                        v-model="signedDocs"
                        label=""
                        :enabled="false"
                        :multiple="true"
                        :showFileOnly="true"
                        :isBoxed="false"
                        :usePortalViewer="false"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <file-selector
                    v-model="cdkProcessedReceiptFiles"
                    label="Receipts"
                    :required="false"
                    :multiple="true"
                    :isBoxed="false"
                    :usePortalViewer="false"
                    @uploadStarted="uploadStarted"
                    @uploadComplete="uploadComplete">
                  </file-selector>
                </div>
              </div>
            </section>
          <footer class="modal-card-foot">
            <button :disabled="isUploadingFile" class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmPaymentProcessedInCdk(true)" type="button">Yes</button>
            <button class="button is-danger" @click="onConfirmPaymentProcessedInCdk(false)" type="button">No</button>
          </footer>
        </div>
        <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
      </b-modal>
    </portal>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import formattingMixin from '@/mixins/accounting/formatting'
import utilitiesMixin from '@/mixins/generic/utilities'
import paymentTrackingService from '@/services/paymentTrackingService'
import squareService from '@/services/squareService'
import printRequestService from '@/services/printRequestService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import FileSelector from '@/components/generic/FileSelector'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import PaymentConversionControl from '@/components/generic/PaymentConversionControl.vue'

export default {
  name: 'MarkDepositPaidControl',
  mixins: [formattingMixin, utilitiesMixin, dealInventoryStyleClasses],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'file-selector': FileSelector,
    'payment-conversion-control': PaymentConversionControl
  },
  props: {
    paymentTracking: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: true
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    isMarkPaidFromNotYetPaidMode: {
      type: Boolean,
      default: false
    },
    isAddingPaymentActive: {
      type: Boolean,
      default: false
    },
    showActionButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isSaving: false,
      isLoading: false,
      serverErrors: [],
      serverErrorMessage: '',
      currentPrintRequest: null,
      signedDocs: [],
      confirmProcessedInCdkActive: false,
      cdkProcessedReceiptFiles: [],
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    hasSquareMarkPaidAccess: function () {
      return this.hasFeatureAccess('deposit.square.markpaid')
    },
    listIconTitle: function () {
      return 'Click to mark this deposit as paid.'
    },
    hasSignedDocs: function () {
      return this.signedDocs.length > 0
    },
    canShowMarkDepositPaidControl: function () {
      if (this.isMarkPaidFromNotYetPaidMode) {
        return this.paymentTracking && this.paymentTracking.paymentStatus === 'NotYetPaid' && this.hasSquareMarkPaidAccess
      }
      return this.paymentTracking && this.paymentTracking.paymentStatus === 'PendingInAccounting' && this.hasSquareMarkPaidAccess
    },
    paymentTrackingId: function () {
      return this.paymentTracking ? this.paymentTracking.id : 0
    },
    isRoService: function () {
      return this.paymentTracking && this.paymentTracking.roNumber
    },
    isPartsInvoice: function () {
      return this.paymentTracking && this.paymentTracking.partsId
    },
    signedDocLabel: function () {
      return this.isRoService ? 'Signed RO' : 'Signed Invoice'
    }
  },
  methods: {
    async onConfirmPaymentProcessedInCdk (confirmed) {
      let that = this
      if (confirmed) {
        this.serverErrorMessage = ''
        this.serverErrors = []
        this.isSaving = true
        let printRequestId = this.currentPrintRequest ? this.currentPrintRequest.id : null
        let confirmPaymentProcessedInCdkModel = {
          paymentTrackingId: this.paymentTracking.id,
          statusType: 'Paid',
          attachments: [],
          printRequestId: printRequestId
        }
        this.cdkProcessedReceiptFiles = this.cdkProcessedReceiptFiles.filter((x) => x.status === 'Active')
        if (this.cdkProcessedReceiptFiles.length > 0) {
          var pdfFiles = this.cdkProcessedReceiptFiles.map((x) => {
            return {
              uploadName: x.name,
              uploadSize: x.size,
              type: x.type,
              classType: 'CdkReceipt',
              uniqueId: x.uniqueId
            }
          })
          confirmPaymentProcessedInCdkModel.attachments.push(...pdfFiles)
        }
        paymentTrackingService.confirmPaymentProcessedInCdk(confirmPaymentProcessedInCdkModel).then(response => {
          that.isSaving = false
          if (response.errors) {
            that.serverErrors = response.errors
            that.confirmProcessedInCdkActive = false
          } else {
            eventBus.$emit('quickview-refresh')
            eventBus.$emit('reload-payment-tracking-list')
            that.$emit('update:isAddingPaymentActive', false)

            // check if we coming from payment control
            if (!that.showActionButton) {
              eventBus.$emit('close-payment-control', true)
            }

            that.confirmProcessedInCdkActive = false
          }
        }).catch((error) => {
          that.serverErrorMessage = error.message
          that.isSaving = false
          that.confirmProcessedInCdkActive = false
        })
      } else {
        // check if we coming from payment control
        if (!that.showActionButton) {
          eventBus.$emit('quickview-refresh')
          eventBus.$emit('reload-payment-tracking-list')
          eventBus.$emit('close-payment-control', true)
        }
        that.confirmProcessedInCdkActive = false
      }
    },
    async onLaunchConfirmPaidDeposit () {
      this.currentPrintRequest = null
      this.signedDocs = []

      if (this.isRoService || this.isPartsInvoice) {
        await this.setSignedDocs()
        return
      }

      this.confirmProcessedInCdkActive = true
    },
    async setSignedDocs () {
      await this.loadPrintRequest()
      if (this.currentPrintRequest && this.currentPrintRequest.attachments) {
        this.currentPrintRequest.attachments.filter(y => y.signed && y.status === 'Active').forEach(x => {
          x.loaded = true
          x.imageData = x.signedFileLocation
          x.fileType = this.getFileType(x.signedFileLocation)
          this.signedDocs.push(x)
        })
      }
    },
    async loadPrintRequest () {
      let that = this
      this.isLoading = true

      if (this.isRoService) {
        await printRequestService.getByRoNumberAmount(this.paymentTracking.roNumber, this.paymentTracking.dealerId, this.paymentTracking.amount).then(response => {
          that.currentPrintRequest = response.printRequest
          that.isLoading = false
          that.confirmProcessedInCdkActive = true
        }).catch((error) => {
          that.isLoading = false
          that.serverErrorMessage = error.message
        })
      }

      if (this.isPartsInvoice) {
        await printRequestService.getByPartsInvoiceAmount(this.paymentTracking.partsInvoiceNo, this.paymentTracking.dealerId, this.paymentTracking.amount).then(response => {
          that.currentPrintRequest = response.printRequest
          that.isLoading = false
          that.confirmProcessedInCdkActive = true
        }).catch((error) => {
          that.isLoading = false
          that.serverErrorMessage = error.message
        })
      }
    },
    onStockNoClick: function (row, modal) {
      let that = this
      this.$copyText(row.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + row.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )

      if (!modal) {
        let inventoryId = row.inventoryId
        if (+inventoryId !== -1 && this.hasFeatureAccess('inventory.view')) {
          this.$router.push({name: 'InventoryQuickView', params: { id: inventoryId, isServiceRequestPod: false, isLoanerPod: false, offerPod: -1 }})
        }
      }
    },
    async downloadInvoice () {
      let that = this
      if (this.paymentTracking.sqInvoiceUrl && this.paymentTracking.sqInvoiceUrl !== '') {
        this.isLoading = true
        await squareService.getDownloadInvoiceUrl(this.paymentTracking.sqInvoiceUrl).then(response => {
          that.isLoading = false
          if (response.errors) {
            that.failedToast('Oops! Something went wrong')
            that.serverErrors = response.errors
          } else {
            let url = response.invoiceUrl.url
            window.open(url, '_blank')
          }
        }).catch((error) => {
          that.failedToast('Oops! Something went wrong')
          that.serverErrorMessage = error.message
          that.isLoading = false
        })
      }
    },
    onDownloadSquareReceipt: function () {
      let sqReceiptUrl = this.paymentTracking.sqReceiptUrl
      let pdfUrl = process.env.VUE_APP_ROOT_DOC_SITE + 'docs/convert?url=' + sqReceiptUrl + '&autodownload=false'
      window.open(pdfUrl)
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  watch: {
  },
  mounted: function () {
    if (this.showDialog === true) {
      this.onLaunchConfirmPaidDeposit()
    }
  },
  beforeDestroy () {
  },
  updated: function () {
  }
}

</script>

<style scoped>

</style>
