<template>
  <div v-observe-visibility="visibilityChanged" class="pod tile is-parent is-narrow">
    <article class="tile is-child box is-paddingless">
      <h4 class="has-text-weight-bold">Inventory - Needed: {{title}} ({{total}} needed)
        <i class="fas fa-ellipsis-v icon-more"></i>
        <i style="display: inline-block;" class="fas fa-sync" :class="{'fa-spin': isLoading}" @click="onPodRefreshRequired"></i>
        <i class="fas fa-eye icon-more" title="These inventory items need to be located"></i>
      </h4>
      <div class="pod-content scrollbar">
        <div class="control-bar is-clearfix is-certical-center">
          <b-field  class="control-bar-radio-list is-pulled-left">
            <b-radio-button v-model="inventoryType" size="is-small" native-value="-1" type="is-default-selected"><span>All</span></b-radio-button>
            <b-radio-button v-model="inventoryType" size="is-small" native-value="0" type="is-success" @click="loadNewInventoryData"><span>New</span></b-radio-button>
            <b-radio-button v-model="inventoryType" size="is-small" native-value="1" type="is-success" @click="loadUsedInventoryData"><span>Used</span></b-radio-button>
          </b-field>
        </div>
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th class="is-uppercase no-wrap-table-cell" :class="{'is-current-sort': sortField === column.id}" v-for="column in inventoryData.columns.filter((x) => x.visible)" :key="column.name" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                {{column.name}}
                <span class="icon sort-icon"><i class="mdi" :class="{'mdi-arrow-up': sortOrder === 'asc', 'mdi-arrow-down': sortOrder === 'desc'}" v-if="sortField === column.id"></i></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable" v-for="(item, index) in inventoryData.data" :key="item.id" :class="{'pod-row-selected': isRowSelected(item)}" :title="getItemDealInformation(item)">
              <td v-for="column in inventoryData.columns.filter((x) => x.visible)" :key="column.name + index.toString()" @click="onItemClick(item)" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                <template v-if="column.format && column.format === 'currency'">{{item[column.id] | currency('$', 0)}}</template>
                <template v-else-if="column.format && column.format === 'datetime'">{{item[column.id] | formatDateOnly}}</template>
                <template v-else-if="column.name === '...'">
                  <span class="actionButtonFormat">
                    <span style="width:100%" class="icon is-block has-text-centered" title="launch located vehicle action list"  @click.stop.prevent="onLaunchWizard(item)">
                      <i class="fal fa-ellipsis-h-alt fa-lg"/>
                    </span>
                  </span>
                </template>
                <template v-else>{{item[column.id]}}</template>
              </td>
            </tr>
            <tr v-if="this.inventoryData.data.length === 0" class="subtitle is-7">
              <td style="text-align:center; height:100px;vertical-align:middle;" :colspan="inventoryData.columns.length">No data available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
    <portal to="global-modal-portal" v-if="isLocateWizardActive">
      <b-modal :active.sync="isLocateWizardActive" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
        <div class="modal-card" style="width: 400px">
          <header class="modal-card-head">
              <p class="modal-card-title">Locate Vehicle Wizard</p>
          </header>
          <section class="modal-card-body">
            <p v-if="serverErrorMessage.length">
              <b>An unforseen error has occurred:</b>
              <br/>
              <ul class="errors">
                  <li>{{ serverErrorMessage }}</li>
              </ul>
              <br/>
            </p>
            <div class="buttons">
              <a class="button is-fullwidth is-danger is-rounded" @click="onCancelLocate()">
                <span>cancel the locate (and the deal)</span>
                <span class="icon is-small"><i class="fal fa-ban"></i></span>
              </a>
              <a class="button is-info is-fullwidth is-rounded" @click="onCompleteLocate()">
                <span>complete the locate purchase</span>
                <span class="icon is-small"><i class="fal fa-hand-receiving"/></span>
              </a>
              <a class="button is-info is-fullwidth is-rounded" @click="onChangeInventory()">
                <span>change the inventory record on the deal</span>
                <span class="icon is-small"><i class="fal fa-handshake"/></span>
              </a>
              <div class="notification is-info" v-show="showNoDealsMsg">
                <button class="delete" @click="showNoDealsMsg = false"></button>
                  This inventory record does not have any deals.
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-danger" type="button" @click="onLocateWizardClose()">Close</button>
          </footer>
          <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
        </div>
      </b-modal>
    </portal>
    <portal to="global-modal-portal" v-if="isNewLocateCapture">
      <b-modal :full-screen="isMobile" :active.sync="isNewLocateCapture" scroll="keep" :has-modal-card="true" v-if="selectedInventory" :canCancel="['escape', 'x']">
        <locate-capture-detail :inventoryId="selectedInventory.id" v-on:locate-capture-complete="onComplete"></locate-capture-detail>
      </b-modal>
    </portal>
    <!--
    <portal to="global-modal-portal" v-if="isChangeInventoryActive" v-show="false">
      <b-modal :active.sync="isChangeInventoryActive" scroll="keep" :has-modal-card="true" v-if="selectedInventory" :canCancel="['escape', 'x']">
        <div class="modal-card" style="width: 400px">
          <header class="modal-card-head">
              <p class="modal-card-title">Change inventory record</p>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column is-6">
                <inventory-selector
                  v-show="showInventorySelector"
                  :required="showInventorySelector"
                  v-model="selectedInventory"
                  :selectedDealer="this.selectedDealer"
                  :useValidator="this.$validator"
                  validateAs="inventory"
                  :createDefaultPossession="defaultInventoryPossessionStatus"
                  :createDefaultSellerType="defaultInventorySellerType"
                  :isInventorySelectorDisabled="false"
                  :showInboundOnly="showInboundOnly">
                </inventory-selector>
                <make-model-selector
                  :label="makeModelSelectorTitle"
                  v-show="showMakeModelSelector"
                  data-vv-as="selected vehicle"
                  name="selectedMakeModel"
                  :v-validate="{'required': showMakeModelSelector}"
                  v-model="selectedMakeModel"
                  :stockType="selectedStockType"
                  :forceColorSelection="selectedStockType && selectedStockType.name !== 'Order'"
                  :showNotes="showNotes"
                  :showTags="showTags"
                  :showAlternateColors="showAlternateColors"
                  :showSourceSelector="showSourceSelector"
                  :showVinSelector="showVinSelector"
                  :showCostSelector="selectedStockType && selectedStockType.name !== 'Order'"
                  :showAskingPriceSelector="true"
                  :showTypeSelector="true"
                  :showMileageSelector="locateVehicleIsFound"
                  :captureTitleStatus="locateVehicleIsFound"
                  :isLocated="locateVehicleIsFound"
                  :forceVinCollection="locateVehicleIsFound"
                  :canCancelDialog="true">
                </make-model-selector>
                <span v-show="errors.has('selectedMakeModel')" class="help is-danger"><i v-show="errors.has('selectedMakeModel')" class="fas fa-exclamation-triangle"></i> {{ errors.first('selectedMakeModel') }}</span>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-info" type="button" @click="onChangeInventoryClose()">Close</button>
          </footer>
        </div>
      </b-modal>
    </portal>
    -->
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import axios from 'axios'
import inventoryService from '@/services/inventoryService'
import dealService from '@/services/dealService'
import InventoryLocatedCaptureDetail from '@/components/generic/inventory/InventoryLocatedCaptureDetail'
import InventorySelector from '@/components/generic/InventorySelector'
import MakeModelSelector from '@/components/generic/MakeModelSelector'
import utilitiesMixin from '@/mixins/generic/utilities'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'InventoryLocatePod',
  mixins: [utilitiesMixin],
  data () {
    return {
      title: '',
      total: 0,
      isLoading: false,
      page: 0,
      sortField: 'name',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      serverErrorMessage: '',
      perPage: this.$store.state.config.defaultPageSize,
      inventoryData: {
        columns: [
          {
            id: 'id',
            name: 'Id',
            visible: false
          },
          {
            id: 'searchType',
            name: 'Type',
            visible: true
          },
          {
            id: 'name',
            name: 'Vehicle',
            visible: true
          },
          {
            id: 'exteriorColor',
            name: 'Ext. Color',
            visible: true
          },
          {
            id: 'interiorColor',
            name: 'Int. Color',
            visible: !this.$isMobile
          },
          {
            id: 'actions',
            name: '...',
            visible: true
          }
        ],
        data: []
      },
      isLocateWizardActive: false,
      selectedInventory: null,
      isNewLocateCapture: false,
      isChangeInventoryActive: false,
      deal: null,
      showNoDealsMsg: false,
      inventoryType: '-1',
      isMobile: window.innerWidth <= 700
    }
  },
  components: {
    'locate-capture-detail': InventoryLocatedCaptureDetail,
    'inventory-selector': InventorySelector,
    'make-model-selector': MakeModelSelector
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentUser', 'currentPendingActions', 'currentNotifications']),
    ...mapDealerState(['currentDealer']),
    showMakeModelSelector: function () {
      return (this.selectedStockType !== null && (this.selectedStockType.name === 'Locate' || this.selectedStockType.name === 'Order'))
    },
    showInventorySelector: function () {
      return (this.selectedStockType !== null && this.selectedStockType.name !== 'Locate' && this.selectedStockType.name !== 'Order')
    },
    defaultInventoryPossessionStatus: function () {
      if (this.selectedDealType && this.selectedDealType.altName === 'Ordered') {
        return this.definedTypes.possessionStatuses.find(x => x.id === 1)
      } else {
        return null
      }
    },
    defaultInventorySellerType: function () {
      if (this.defaultInventoryPossessionStatus && this.defaultInventoryPossessionStatus.id === 1) {
        return this.definedTypes.allowedSourceTypes.options.find(x => x.id === this.definedTypes.allowedSourceTypes.Factory)
      } else {
        return null
      }
    }
  },
  methods: {
    isRowSelected: function (item) {
      if (!this.selectedInventory) return false
      if (this.selectedInventory.id === item.id) return true
      return false
    },
    onItemClick: function (item) {
      this.selectedInventory = item
      eventBus.$emit('pod-item-highlight', {componentId: this._uid})
      if (this.hasFeatureAccess('inventory.view')) {
        this.$router.push({name: 'InventoryQuickView', params: { id: item.id, isServiceRequestPod: false, isLoanerPod: false, offerPod: -1 }})
      }
    },
    onPodRefreshRequired: function () {
      this.loadData()
    },
    loadData: function () {
      if (this.inventoryType === '0') {
        this.loadNewInventoryData()
      } else if (this.inventoryType === '1') {
        this.loadUsedInventoryData()
      } else {
        this.loadAllData()
      }
    },
    loadAllData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        query: '',
        pageSize: this.perPage,
        sortBy: 'name',
        page: this.page
      }
      this.isLoading = true
      inventoryService.getInventoryToLocate(searchCriteria).then(response => {
        this.inventoryData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        this.title = 'All'
        response.results.forEach((item) => {
          this.inventoryData.data.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.inventoryData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    loadNewInventoryData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        query: '',
        pageSize: this.perPage,
        sortBy: 'stockNo',
        page: this.page
      }

      this.isLoading = true
      inventoryService.getInventoryToLocateNew(searchCriteria).then(response => {
        this.inventoryData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        this.title = 'New'
        response.results.forEach((item) => {
          this.inventoryData.data.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.inventoryData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    loadUsedInventoryData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        query: '',
        pageSize: this.perPage,
        sortBy: 'stockNo',
        page: this.page
      }

      this.isLoading = true
      inventoryService.getInventoryToLocateUsed(searchCriteria).then(response => {
        this.inventoryData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        this.title = 'Used'
        response.results.forEach((item) => {
          this.inventoryData.data.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.inventoryData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    onLocateWizardClose () {
      this.isLocateWizardActive = false
    },
    onLaunchWizard (item) {
      this.selectedInventory = item
      this.isLocateWizardActive = true
      this.showNoDealsMsg = false
    },
    onCancelLocate () {
      if (this.selectedInventory) {
        this.onLocateWizardClose()
        this.isLoading = true
        dealService.cancelLocate(this.selectedInventory.id).then(response => {
          this.isLoading = false
          if (response.errors) {
            this.serverErrors = response.errors
            this.failedToast('Error: ' + this.serverErrors)
          } else {
            this.onPodRefreshRequired()
            this.successToast('Success! Locate cancelled')
          }
        }).catch((error) => {
          throw error
        })
      }
    },
    onComplete () {
      this.isNewLocateCapture = false
    },
    onCompleteLocate () {
      this.onLocateWizardClose()
      this.isNewLocateCapture = true
    },
    onChangeInventory () {
      // this.isChangeInventoryActive = true
      // this.onLocateWizardClose()
      this.loadDeal()
    },
    onChangeInventoryClose () {
      this.isChangeInventoryActive = false
    },
    onDealEdit: function () {
      if (this.deal) {
        let stockType = { id: 0, name: 'In Stock', altName: 'InStock' }
        let dealStatus = { id: 0, name: 'Awaiting Arrival', altName: 'AwaitingArrival' }
        this.$router.push({name: 'EditDeal', params: { id: this.deal.id, stockType: stockType, dealStatus: dealStatus }})
      } else {
        this.showNoDealsMsg = true
      }
    },
    loadDeal () {
      this.showNoDealsMsg = false
      this.serverErrorMessage = ''
      this.isLoading = true
      dealService.byInventory(this.selectedInventory.id).then(response => {
        this.deal = response.results.find((x) => x.items.find((y) => y.isPrimary === true) !== null)
        this.onDealEdit()
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        if (this.currentDealer.id) {
          this.loadData()
        }
      }
    },
    getItemDealInformation: function (item) {
      let information = ''
      if (item.dealCode) {
        information = information + 'Deal #: ' + item.dealCode + ', '
      }
      if (item.dealStatusType) {
        information = information + 'Deal Status: ' + item.dealStatusType + ', '
      }
      if (item.saleType) {
        information = information + 'Sale Type: ' + item.saleType + ', '
      }
      if (item.salesAgent) {
        information = information + 'Sales Agent: ' + item.salesAgent + ', '
      }
      if (item.customer) {
        information = information + 'Customer: ' + item.customer
      }
      information = information.replace(/,\s*$/, '')
      return information
    },
    itemHighLightCheck: function (options) {
      let componentId = options.componentId
      if (this._uid !== componentId) {
        this.selectedInventory = null
      }
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      if (newValue !== undefined) {
        this.loadData()
      }
    },
    inventoryType: function (newValue, oldValue) {
      this.loadData()
    }
  },
  beforeDestroy () {
    axios.cancel('inventory-locate')
    eventBus.$off('reload-locate-list', this.loadData)
    eventBus.$off('pod-item-highlight', this.itemHighLightCheck)
  },
  mounted: function () {
    eventBus.$on('reload-locate-list', this.loadData)
    eventBus.$on('pod-item-highlight', this.itemHighLightCheck)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .no-wrap-table-cell {
    white-space: nowrap;
  }

  .sort-icon {
    height:auto !important;
  }

  .is-current-sort {
    color: rgb(0, 177, 31) !important;
  }

</style>
