<template>
  <div>
    <div class="columns is-multiline" v-if="!isButtonMode">
      <div class="column is-12 side-padding-sm">
        <p class="is-marginless is-paddingless is-pulled-right subtitle is-7">showing top {{ this.$store.state.config.maxLoanerEvents }}</p>
      </div>
      <div class="column is-12 no-h-padding">
        <b-table
          :data="currentInventory.loanerEvents"
          :sortable="false"
          :row-class="() => 'loaner-event-row'"
          @select="onRowSelected"
          :mobile-cards="false"
          @dblclick="onRowSelected">
          <template slot-scope="props">
              <b-table-column field="type" label="Status" class="loaner-event-column">
                <i class="icon fal fa-arrow-circle-right fa-lg" :class="getColorClassByLoanerEventStatus(props.row.typeId)" title="Loaner Status"/>
                <i class="icon fal fa-envelope-open-text fa-lg list-icon" :class="getColorClassByAgreementStatus(props.row.borrowAgreementStatus)" :title="'Borrowers Agreement Status: ' + getDescriptionByAgreementStatus(props.row.borrowAgreementStatus)" v-if="canShowBorrowAgreementStatus(props.row)"/>
              </b-table-column>
              <b-table-column field="type" label="Type" class="loaner-event-column">
                {{ displayRowType(props.row.type) }}
              </b-table-column>
              <b-table-column field="loanedByName" label="Borrower" class="loaner-event-column">
                {{ props.row.loanedByName }}
              </b-table-column>
              <b-table-column field="createdBy" label="Creator" class="loaner-event-column">
                {{ props.row.createdBy }}
              </b-table-column>
              <b-table-column field="createdAt" label="Created" class="loaner-event-column">
                {{ props.row.createdAt | formatDateOnly }}
              </b-table-column>
              <b-table-column field="usage" label="Miles" class="loaner-event-column">
                {{ props.row.currentUsage }}
              </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="has-text-centered">
                <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                <p>No loaner records avaiable for this vehicle</p>
              </div>
            </section>
          </template>
        </b-table>
        <a class="show-more-link link is-size-7 is-pulled-right" @click="onShowMoreLoanerEvents" v-if="hasMoreLoanerEvents">view all</a>
      </div>
    </div>

    <quick-action-container v-if="!isButtonMode">
      <div class="is-inline-block" v-if="hasLoanerEventAccess">
          <div class="quick-action" >
            <button v-if="!isPpaClosed" type="button" class="button" @click="isCreatingNewLoanerEvent(true)" title="Click to create a new loaner event.">
              <b-icon pack="fal" icon="plus" custom-size="fa-lg"></b-icon>
            </button>
          </div>
      </div>
    </quick-action-container>

    <portal to="global-modal-portal" v-if="isSignatureActive">
      <b-modal :active.sync="isSignatureActive" scroll="keep" :has-modal-card="true" v-if="isSignatureActive" :canCancel="false">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="is-block modal-card-title">
                Signing Loaner Agreement
                <span class="help has-text-weight-light">use the signature pad below to capture your signature</span>
              </p>
          </header>
          <section class="modal-sign-body">
            <vue-signature ref="signature" :sigOption="option" :w="'500px'" :h="'150px'"></vue-signature>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': loadingSign }" @click.prevent="onSign">Sign</button>
            <button class="button is-warning" @click.prevent="onClearSign">Clear</button>
            <button class="button is-danger" @click.prevent="onCancelSignNow">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>

    <pdf-modal
        :pdfFile="pdfModalFile"
        :usePortal="true"
        :portalOrder="2"
        v-on:on-pdf-downloaded="onPreviewed"
        v-on:on-pdf-closed="onPreviewClosed"
        v-if="pdfModalFile && pdfModalFile.src"
        :fullScreenMode="false">
    </pdf-modal>

    <portal to="global-modal-portal" v-else-if="isNewLoanerEvent">
      <b-modal :active.sync="isNewLoanerEvent" scroll="keep" :has-modal-card="true" :canCancel="false" class="loaner-event-container-modal">
        <div class="modal-card" v-if="!cropDriverPhotoActive">
          <header class="modal-card-head">
            <div class="columns is-multiline">
              <div class="subtitle is-6 has-text-info is-marginless" style="width:50%">Loaner Event</div>
              <div class="" style="width:50%">
                <span class="subtitle is-6 is-pulled-right has-text-primary">
                {{inventoryOverview}}
                </span>
              </div>
              <service-loaner-out-of-service-action :inventory="currentInventory" v-if="currentInventory && allowService"></service-loaner-out-of-service-action>
            </div>
          </header>
          <section class="modal-info-body view-container small-padding-top small-padding-bottom side-padding-sm" style="background: white !important;">
            <b-tabs size="is-small" :animated="false" type="is-toggle" @change="loadData()" v-model="tabIndex">
              <b-tab-item label="Definition" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
                <form @submit.prevent="validateBeforeSubmitWithCallBack" @change="onFormChange">
                  <div class="box">
                    <h3 class="title is-6">Loaner Details</h3>
                    <div class="columns is-multiline">
                      <div class="column is-9">
                        <div class="field" v-if="!readOnly">
                          <button-list-selector :readonly="readOnly" validateAs="loaner status" label="Loaner Status" :required="true" v-model="selectedLoanerStatus" :useValidator="thisValidator"
                            :availableValues="this.definedTypes.loanerEventType.options" :activeValues="loanerEventTypeActiveValues" :hideInactiveValues="true"></button-list-selector>
                        </div>
                        <div class="field" v-else>
                          <label class="label">Loaner Status</label>
                          <div class="field">
                            {{ displayType }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field" v-if="!readOnly">
                          <label class="label">Mileage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control is-vertical-center">
                            <input name="usage" :readonly="readOnly" data-vv-as="miles" v-validate="'numeric|required'" :class="{'input': true, 'is-danger': errors.has('usage') }" type="number" placeholder="#####" v-model="currentInventory.usage" :min="minimumUsageAllowed">
                          </div>
                          <span v-show="errors.has('usage')" class="help is-danger"><i v-show="errors.has('usage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('usage') }}</span>
                        </div>
                        <div class="field" v-else>
                          <label class="label">Mileage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control is-vertical-center">
                            <input :readonly="readOnly" :class="{'input': true}" type="number" placeholder="#####" v-model="currentLoan.currentUsage">
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-if="!showReturnedSection">
                      <div class="columns is-multiline">
                        <div class="column is-6">
                            <div class="field">
                            <tag-number-selector
                              :selectedDealer="selectedDealer"
                              v-model="currentLoan.tagNumber"
                              :isDisabled="readOnly"
                              label="Tag Number"
                              validateAs="tag number"
                              :showReserved="isServiceLoaner"
                              :required="false">
                            </tag-number-selector>
                          </div>
                        </div>
                        <div class="column is-6" v-if="requiresServiceAdvisor">
                          <user-selector
                            :selectedDealer="currentDealer"
                            v-model="selectedAdvisor"
                            validateAs="advisor"
                            label="Advisor"
                            filter="Service Advisor"
                            :required="true"></user-selector>
                        </div>
                      </div>
                    </template>
                    <div class="columns is-multiline">
                      <div class="column is-6" v-if="showCreditCardBox">
                        <label class="label">Scheduled Return Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <el-date-picker
                            :readonly="readOnly"
                            v-validate="'required'"
                            data-vv-as="return date and time"
                            name="returnTime"
                            @change="onFormChange()"
                            v-model="currentLoan.returnTime"
                            type="datetime"
                            placeholder="Click to select..."
                            popper-class="datepicker-container">
                          </el-date-picker>
                          <span v-show="errors.has('returnTime')" class="help is-danger"><i v-show="errors.has('returnTime')" class="fas fa-exclamation-triangle"></i> {{ errors.first('returnTime') }}</span>
                        </div>
                      <div class="column is-6" v-if="isServiceLoaner">
                        <div class="field">
                          <label class="label">RO Number</label>
                          <div class="control is-vertical-center">
                            <input name="ronumber" :readonly="readOnly" data-vv-as="ronumber" :class="{'input': true, 'is-danger': errors.has('ronumber') }" type="text" v-model="currentLoan.roNumber">
                          </div>
                          <span v-show="errors.has('ronumber')" class="help is-danger"><i v-show="errors.has('ronumber')" class="fas fa-exclamation-triangle"></i> {{ errors.first('ronumber') }}</span>
                        </div>
                      </div>
                    </div>
                    <quick-action-container v-if="!showReturnedSection && lastSignedBorrowersAgreement !== ''">
                      <div class="control is-inline-block quick-action">
                        <button  class="button is-success is-pulled-right" @click.prevent="onDownloadBorrowersAgreement" title="Click to download the last signed borrowers agreement.">
                          <b-icon pack="fal" icon="cloud-download" custom-size="fa-lg"></b-icon>
                        </button>
                      </div>
                    </quick-action-container>
                  </div>

                  <template v-if="!showReturnedSection">

                    <div class="box" v-if="isCustomerDriver">
                      <h3 class="title is-6">Customer Details</h3>
                      <div class="columns">
                        <div class="column is-12">
                          <customer-selector
                            :selectedDealer="selectedDealer"
                            v-model="selectedCustomer"
                            :isDisabled="readOnly"
                            :required="true"
                            :useValidator="thisValidator">
                          </customer-selector>
                        </div>
                      </div>

                      <div class="columns" v-if="selectedCustomer">
                        <div class="column is-6">
                          <div class="field">
                            <label class="label">Customer Email Address<b-icon pack="fas" :icon="isEmailRequired ? 'star' : ''" class="fa-ss"></b-icon></label>
                            <div class="control">
                              <input name="customerEmail" :readonly="readOnly" v-validate="{'required' : isEmailRequired, 'email': true}" data-vv-as="customer email" :class="{'input': true, 'is-danger': errors.has('customerEmail') }" type="text" v-model="selectedCustomer.email" data-lpignore="true" autocomplete="off">
                              <span v-show="errors.has('customerEmail')" class="help is-danger"><i v-show="errors.has('customerEmail')" class="fas fa-exclamation-triangle"></i> {{ errors.first('customerEmail') }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="column is-6">
                          <div class="field">
                            <label class="label">Customer Mobile<b-icon pack="fas" :icon="isMobileRequired ? 'star' : ''" class="fa-ss"></b-icon></label>
                            <div class="control">
                              <input name="customerMobile" :readonly="readOnly" v-validate="{'required' : isMobileRequired}" data-vv-as="customer mobile" :class="{'input': true, 'is-danger': errors.has('customerMobile') }" type="text" v-model="selectedCustomer.mobile" v-mask="'+1(###)-###-####'" data-lpignore="true" autocomplete="off">
                              <span v-show="errors.has('customerMobile')" class="help is-danger"><i v-show="errors.has('customerMobile')" class="fas fa-exclamation-triangle"></i> {{ errors.first('customerMobile') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns is-multiline" v-if="!readOnly">
                        <div class="column is-12">
                          <license-selector
                          v-model="driversPhotoFiles"
                          label="Drivers License"
                          :multiple="false"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="cropDriverPhoto"
                          :required="isCustomerDriver"
                          :isBoxed="false"
                          data-vv-as="drivers license">
                          </license-selector>
                        </div>
                        <div class="column is-12" v-if="stateRequiredBackOfDriversLicense">
                          <license-selector
                            v-model="backOfDriversPhotoFiles"
                            label="Back of Drivers License"
                            :multiple="false"
                            @uploadStarted="uploadStarted"
                            @uploadComplete="uploadComplete"
                            :required="isCustomerDriver && stateRequiredBackOfDriversLicense"
                            :isBoxed="false"
                            data-vv-as="back of drivers license">
                          </license-selector>
                        </div>
                        <div v-if="!isTestDrive" class="column is-12">
                          <file-selector
                          :isBoxed="false"
                          v-model="insurancePhotoFiles"
                          label="Insurance Card"
                          :multiple="false"
                          :required="false"
                          data-vv-as="insurance card"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </file-selector>
                        </div>
                        <div class="column is-12">
                          <file-selector
                          :isBoxed="false"
                          v-model="customerPhotoFiles"
                          label="Customer Photo"
                          :multiple="false"
                          :croppedCustomerImages="croppedCustomerImages"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </file-selector>
                          <div class="inner-add-remove-button" v-if="showCropDriverPhotoButton">
                            <a class="button is-info" title="Crop drivers license photo to use as customer photo?" @click="showCropDriversPhotoModal()">
                              <span class="icon">
                                <i class="fas fa-crop"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="box" v-if="showCreditCardBox">
                      <h3 class="title is-6">Credit Card Details</h3>
                      <div class="columns is-multiline" v-if="!readOnly">
                        <div class="column is-12">
                          <div class="field">
                            <label class="label">Name on Card</label>
                            <div class="control">
                              <input name="nameOnCard" placeholder="Name on Card" v-validate="''" data-vv-as="name on card" :class="{'input': true, 'is-danger': errors.has('nameOnCard') }" type="text" v-model="currentLoan.nameOnCard">
                              <span v-show="errors.has('nameOnCard')" class="help is-danger"><i v-show="errors.has('nameOnCard')" class="fas fa-exclamation-triangle"></i> {{ errors.first('nameOnCard') }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="column is-4">
                          <div class="field">
                            <label class="label">Card Number</label>
                            <div class="control">
                              <input name="cardNumber" placeholder="Card Number" v-validate="''" data-vv-as="card number" :class="{'input': true, 'is-danger': errors.has('cardNumber')}" type="tel" v-model="currentLoan.cardNumber" v-mask="'#### #### #### ####'">
                              <span v-show="errors.has('cardNumber')" class="help is-danger"><i v-show="errors.has('cardNumber')" class="fas fa-exclamation-triangle"></i> {{ errors.first('cardNumber') }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="column is-2">
                          <div class="field">
                          <label class="label">Expiry Date</label>
                            <div class="control">
                              <input name="expiryDate" placeholder="MM/YY" v-validate="''" data-vv-as="expiry date" :class="{'input': true, 'is-danger': errors.has('expiryDate') }" type="tel" v-model="currentLoan.expiryDate" v-mask="'##/##'">
                              <span v-show="errors.has('expiryDate')" class="help is-danger"><i v-show="errors.has('expiryDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('expiryDate') }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="column is-2">
                          <div class="field">
                            <label class="label">CVC</label>
                            <div class="control">
                              <input name="cvr" placeholder="CVC" v-validate="''" data-vv-as="cvr" :class="{'input': true, 'is-danger': errors.has('cvr')}" type="number" v-model="currentLoan.cvr" v-mask="applicableCvrMask">
                              <span v-show="errors.has('cvr')" class="help is-danger"><i v-show="errors.has('cvr')" class="fas fa-exclamation-triangle"></i> {{ errors.first('cvr') }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="column is-4">
                          <div class="field">
                            <label class="label">Card Type</label>
                            <div class="control">
                              <multiselect
                                @select="onFormChange()"
                                name="cardType"
                                v-model="currentLoan.cardType"
                                :disabled="readOnly"
                                data-vv-validate-on="input|close"
                                data-vv-as="card type"
                                v-validate="{'required': false}"
                                :options="['Amex', 'MasterCard', 'Visa', 'DinersClub']"
                                :hideSelected="true">
                                </multiselect>
                              <span v-show="errors.has('cardType')" class="help is-danger"><i v-show="errors.has('cardType')" class="fas fa-exclamation-triangle"></i> {{ errors.first('cardType') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="has-text-danger is-size-7">Note: Card details hidden for security reasons</div>
                        <br/>
                      </div>

                      <address-capture v-model="address" addressType="Billing" :readOnly="readOnly" :useValidator="thisValidator"></address-capture>

                      <div class="columns" v-if="!readOnly">
                        <div class="column is-12">
                          <file-selector
                          :isBoxed="false"
                          v-model="cardPhotoFiles"
                          label="Credit Card"
                          :multiple="false"
                          :required="false"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </file-selector>
                        </div>
                      </div>
                    </div>

                    <div class="box" v-if="requiresInsuranceDetails">
                      <h3 class="title is-6">Insurer Details</h3>
                      <div class="columns is-multiline">
                        <div class="column is-6">
                          <div class="field">
                            <insurer-selector
                              :selectedDealer="selectedDealer"
                              v-model="currentLoan.insurer"
                              :isDisabled="readOnly"
                              label="Insurer"
                              validateAs="insured from insurer"
                              :required="false">
                            </insurer-selector>
                          </div>
                        </div>
                        <div class="column is-6">
                          <div class="field">
                            <label class="label">Policy Number</label>
                            <div class="control">
                              <input name="policyNumber" :readonly="readOnly" v-validate="''" data-vv-as="policy number" :class="{'input': true, 'is-danger': errors.has('policyNumber')}" type="text" v-model="currentLoan.policyNumber">
                              <span v-show="errors.has('policyNumber')" class="help is-danger"><i v-show="errors.has('policyNumber')" class="fas fa-exclamation-triangle"></i> {{ errors.first('policyNumber') }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="column is-6">
                          <div class="field">
                            <label class="label">Agent</label>
                            <div class="control">
                              <input name="agentName" :readonly="readOnly" v-validate="''" data-vv-as="agent name" :class="{'input': true, 'is-danger': errors.has('agentName')}" type="text" v-model="currentLoan.agentName">
                              <span v-show="errors.has('agentName')" class="help is-danger"><i v-show="errors.has('agentName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('agentName') }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="column is-6">
                          <div class="field">
                            <label class="label">Agent Contact No.</label>
                            <div class="control">
                              <input name="agentContactNo" :readonly="readOnly" v-validate="''" data-vv-as="agent contact number" :class="{'input': true, 'is-danger': errors.has('agentContactNo')}" type="text" v-model="currentLoan.agentContactNo" v-mask="'+1(###)-###-####'" data-lpignore="true" autocomplete="off">
                              <span v-show="errors.has('agentContactNo')" class="help is-danger"><i v-show="errors.has('agentContactNo')" class="fas fa-exclamation-triangle"></i> {{ errors.first('agentContactNo') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="box" v-if="isEmployeeDriver">
                      <h3 class="title is-6">Employee</h3>
                      <div class="columns is-multiline">
                        <div class="column is-12">
                          <user-selector
                            :selectedDealer="selectedDealer"
                            v-model="selectedEmployee"
                            validateAs="employee"
                            :isDisabled="readOnly"
                            label="Employee"
                            :required="true"
                            :useValidator="thisValidator"></user-selector>
                        </div>
                        <div class="column is-6" v-if="selectedEmployee">
                          <div class="field">
                            <label class="label">Phone<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                            <div class="control">
                              <input name="employeePhone" :readonly="readOnly" v-validate="'required'" data-vv-as="employee phone no" :class="{'input': true, 'is-danger': errors.has('employeePhone') }" type="text" v-model="employeeMobile" v-mask="'+1(###)-###-####'" data-lpignore="true" autocomplete="off">
                              <span v-show="errors.has('employeePhone')" class="help is-danger"><i v-show="errors.has('employeePhone')" class="fas fa-exclamation-triangle"></i> {{ errors.first('employeePhone') }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="column is-6" v-if="selectedEmployee">
                          <div class="field">
                            <label class="label">Email<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                            <div class="control">
                              <input name="employeeEmail" :readonly="readOnly" v-validate="'required'" data-vv-as="employee email address" :class="{'input': true, 'is-danger': errors.has('employeeEmail') }" type="text" v-model="employeeEmail" data-lpignore="true" autocomplete="off">
                              <span v-show="errors.has('employeeEmail')" class="help is-danger"><i v-show="errors.has('employeeEmail')" class="fas fa-exclamation-triangle"></i> {{ errors.first('employeeEmail') }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="column is-6" v-if="selectedEmployee">
                          <file-selector
                          :isBoxed="false"
                          v-model="employeeDriversPhotoFiles"
                          label="Drivers License"
                          :multiple="false"
                          :required="isEmployeeDriver"
                          data-vv-as="drivers license"
                          @uploadComplete="cropEmployeeDriverPhoto"
                          @uploadStarted="uploadStarted">
                          </file-selector>
                        </div>
                        <div class="column is-6" v-if="selectedEmployee">
                          <div class="field">
                            <label class="label">Drivers License No.<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                            <div class="control">
                              <input name="employeeDriversLicenceNumber" v-validate="{'required' : isEmployeeDriver}" data-vv-as="drivers license number" :class="{'input': true, 'is-danger': errors.has('employeeDriversLicenceNumber') }" type="text" v-model="employeeDriversLicenceNumber">
                              <span v-show="errors.has('employeeDriversLicenceNumber')" class="help is-danger"><i v-show="errors.has('employeeDriversLicenceNumber')" class="fas fa-exclamation-triangle"></i> {{ errors.first('employeeDriversLicenceNumber') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="box" v-if="isTestDrive">
                      <h3 class="title is-6">Sales Person</h3>
                      <div class="columns is-multiline">
                        <div class="column is-12">
                          <user-selector
                            :selectedDealer="selectedDealer"
                            v-model="selectedSalesPerson"
                            validateAs="sales person"
                            :isDisabled="readOnly"
                            label="Sales Person"
                            :required="true"
                            :useValidator="thisValidator">
                          </user-selector>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div class="box">
                      <h3 class="title is-6">Damage Details</h3>
                      <div class="columns">
                        <div class="column is-6">
                          <switch-selector type="is-info" label="Damaged" :disabled="readOnly" v-model="currentLoan.isDamaged"></switch-selector>
                        </div>
                        <div class="column" v-if="currentLoan.isDamaged">
                          <div class="field">
                            <label class="label">Description of Damage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                            <div class="control">
                              <textarea :readonly="readOnly" rows="7.5" name="damageDescription" data-vv-as="Description" v-validate="'required'" :class="{'textarea': true, 'is-danger': errors.has('damageDescription') }" v-model="currentLoan.damageDescription"></textarea>
                              <span v-show="errors.has('damageDescription')" class="help is-danger"><i v-show="errors.has('damageDescription')" class="fas fa-exclamation-triangle"></i> {{ errors.first('damageDescription') }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column" v-if="currentLoan.isDamaged">
                          <image-selector
                            v-model="damageFiles"
                            label="Damage Visual Survey"
                            :required="true" :isBoxed="false"
                            :disabled="readOnly"
                            :useValidator="thisValidator"
                            @uploadStarted="uploadStarted"
                            @uploadComplete="uploadComplete">
                          </image-selector>
                        </div>
                      </div>
                    </div>
                  </template>
                </form>
              </b-tab-item>
              <b-tab-item label="History" icon-pack="fas" icon="fas fa-history fa-sm">
                <section>
                  <b-table
                    class="lists"
                    :data="documentSigningRequestData">

                    <template slot-scope="props">
                      <b-table-column field="status" label="Status" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        <i data-type="B" class="icon fal fa-envelope-open-text fa-lg list-icon" :class="getColorClassByAgreementStatus(props.row.signingStatus)" :title="'Borrowers Agreement Status: ' + getDescriptionByAgreementStatus(props.row.signingStatus)"/>
                        <a target="live-doc-download" :href="buildSellersAgreementUrl(props.row.dealFileLocation)" title="Click to download the last signed purchasers agreement."  v-if="props.row.dealFileLocation && (props.row.signingStatus === 10 || props.row.signingStatus === 3)"><i class="icon fal fa-cloud-download fa-lg list-icon has-text-success"/></a>
                      </b-table-column>
                      <b-table-column field="email" label="Recipients" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.destinationAddresses }}
                      </b-table-column>
                      <b-table-column field="type" label="Type" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ formateSignatureType(props.row.type) }}
                      </b-table-column>
                      <b-table-column field="sentAt" label="Sent At" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ sentDate(props.row.sentAt) }}
                      </b-table-column>
                      <b-table-column field="sentAt" label="Time" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ sentTime(props.row.sentAt, props.row.signingStatus) }}
                      </b-table-column>
                      <b-table-column field="createdBy" label="Creator" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.createdBy }}
                      </b-table-column>
                      <b-table-column field="createdAt" label="Created" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.createdAt | formatDateOnly }}
                      </b-table-column>
                      <b-table-column field="id" label="Actions">
                        <a v-if="props.row.cancellationSent === false" @click.stop.prevent="onCancelRequest(props.row)"><i class="icon fa-fw fal fa-times-circle fa-lg" :style="isCancellationDisabled(props.row) ? 'color: red !important' : 'color: blue !important'" :title="isCancellationDisabled(props.row) ? 'Request cannot be cancelled at this point' : 'Cancel Request'"/></a>
                        <a v-else><i class="icon fa-fw fal fa-times-circle fa-lg has-text-success" title='Cancellation request has been made'/></a>
                        <a  v-if="props.row.reminderSent === false" @click.stop.prevent="onRequestReminder(props.row)"><i class="icon fa-fw fal fa-redo fa-lg" :class="{'has-text-link': !isReminderDisabled(props.row), 'has-text-grey': isReminderDisabled(props.row)}" :title="isReminderDisabled(props.row) ? 'Reminder cannot be sent at this point' : 'Send Reminder'"/></a>
                        <a v-else><i class="icon fa-fw fal fa-envelope fa-lg has-text-success" title='Reminder has been sent'/></a>
                      </b-table-column>
                    </template>

                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No loaner documents has been sent for this item.</p>
                        </div>
                      </section>
                    </template>

                  </b-table>
                </section>
              </b-tab-item>
            </b-tabs>
          </section>
          <footer class="modal-card-foot main-dialog-footer">
            <button title="This action will allow you to edit the loaner event" class="button is-primary" v-if="readOnly" @click="onEditLoanerEvent" type="button">Edit</button>
            <button :disabled="isUploadingFile" title="This action will reserve your loaner event but not send out any documentation for signing" class="button is-primary" v-if="!showReturnedSection && !readOnly" :class="{'is-loading': isSaving && !isEmailForSigning && !isSignNow }" @click="onEmailBorrowed(false, false)" type="button">{{displaySave}}</button>
            <button :disabled="isUploadingFile" title="This action will reserve your loaner event and send for remote signing by loanee" class="button is-primary" v-if="!showReturnedSection && !readOnly && showCreditCardBox && !isMobile" :class="{'is-loading': isSaving && isEmailForSigning }" @click="onEmailBorrowed(true, false)" type="button">Email</button>
            <button :disabled="isUploadingFile" class="button is-primary" v-else-if="!showReturnedSection && !readOnly && showCreditCardBox && isMobile" :class="{'is-loading': isSaving && isEmailForSigning }" @click="onEmailBorrowed(true, false)" type="button"><b-icon pack="fal" icon="envelope" custom-size="fa-lg"></b-icon></button>
            <button :disabled="isUploadingFile" title="This action will save your loaner event and allow you to capture a signature immediately" class="button is-primary" v-if="!showReturnedSection && !readOnly && !isMobile" :class="{'is-loading': isSaving && isSignNow }" @click="onEmailBorrowed(true, true)" type="button">Sign</button>
            <button :disabled="isUploadingFile" class="button is-primary" v-else-if="!showReturnedSection && !readOnly && isMobile"  :class="{'is-loading': isSaving && isSignNow }" @click="onEmailBorrowed(true, true)" type="button"><b-icon pack="fal" icon="pencil" custom-size="fa-lg"></b-icon></button>
            <button :disabled="isUploadingFile" title="This action will save your loaner event"  class="button is-primary" v-if="showReturnedSection && !readOnly" :class="{'is-loading': isSaving }" @click="onEmailBorrowed(false, false)" type="button">{{displaySave}}</button>
            <button class="button is-danger" @click="isCreatingNewLoanerEvent(false)" type="button" v-if="!isMobile">{{displayClose}}</button>
            <button class="button is-danger" @click="isCreatingNewLoanerEvent(false)" type="button" v-else-if="isMobile"><b-icon pack="fal" icon="ban" custom-size="fa-lg"></b-icon></button>
            <button class="button is-info" @click="onPreviewAgreement" :class="{'is-loading': isPreviewing }" type="button" v-if="requiresAgreementSigning && !readOnly && !isMobile">Preview Agreement</button>
            <button class="button is-info" @click="onPreviewAgreement" :class="{'is-loading': isPreviewing }" type="button" v-else-if="requiresAgreementSigning && !readOnly && isMobile"><b-icon pack="fal" icon="eye" custom-size="fa-lg"></b-icon></button>
          </footer>
        </div>
        <div v-else>
          <div class="modal-card" style="width: auto" id="crop-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Crop drivers licence photo to use as customer photo?</p>
            </header>
            <section class="modal-card-body" id="crop-card-body">
              <div class="columns">
                <div class="column" v-if="!isLoadingPhoto">
                  <vue-cropper v-show="selectedFile" ref='cropper' :src="selectedFile" alt='Source Image'></vue-cropper>
                </div>
                <div class="aligner-item column" style="text-align:center" v-else>
                  <img src="@/assets/ajax-loader.gif">
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" :class="{'is-loading': isLoadingPhoto }" @click="onSaveCroppedDriverPhoto">Save</button>
              <button class="button is-danger" :class="{'is-loading': isLoadingPhoto }" @click="closeCropDriverPhoto">Cancel</button>
            </footer>
          </div>
        </div>
      </b-modal>
    </portal>
    <send-reminder-control
      v-on:on-hide-reminder-control="onHideReminderModal"
      :showConfirmationModal="showConfirmReminder"
      :signatureRequest="signatureRequest"
      v-on:send-reminder="sendReminder">
    </send-reminder-control>
    <cancel-signature-request-control
      v-on:on-hide-cancel-request-control="onHideCancellationRequestModal"
      :showRequestCancellationModal="showCancelRequest"
      :signatureRequest="cancelSignatureRequest"
      v-on:send-cancellation-request="sendCancellationRequest">
    </cancel-signature-request-control>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import formattingMixin from '@/mixins/inventory/formatting'
import inventoryLoanerEventService from '@/services/inventoryLoanerEventService'
import inventoryService from '@/services/inventoryService'
import AddressCapture from '@/components/generic/AddressCapture'
import QuickActionContainer from '@/components/generic/QuickActionContainer'
import customerService from '@/services/customerService'
import userService from '@/services/userService'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import TagNumberSelector from '@/components/generic/TagNumberSelector'
import InsurerSelector from '@/components/generic/InsurerSelector'
import CustomerSelector from '@/components/generic/CustomerSelector'
import FileSelector from '@/components/generic/FileSelector'
import ImageSelector from '@/components/generic/ImageSelector'
import UserSelector from '@/components/generic/UserSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import SwitchSelector from '@/components/generic/SwitchSelector'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import documentSigningRequestService from '@/services/documentSigningRequestService'
import baseService from '@/services/baseService'
import axios from 'axios'
import VueCropper from 'vue-cropperjs'
import util from '@/utils'
import SendReminderControl from '@/components/generic/SendReminderControl'
import CancelSignatureRequestControl from '@/components/generic/CancelSignatureRequestControl'
import InventoryLoanerRemoveServiceAction from '@/components/generic/inventory/InventoryLoanerRemoveServiceAction'
import LicenseSelector from '@/components/generic/LicenseSelector'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'
import vueSignature from 'vue-signature'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapAllStatesGetters = createNamespacedHelpers('states').mapGetters

export default {
  name: 'InventoryLoanerEventContainer',
  inject: {
    $validator: '$validator'
  },
  mixins: [validateBeforeSubmitMixin, utilitiesMixin, formattingMixin],
  components: {
    'quick-action-container': QuickActionContainer,
    'button-list-selector': ButtonListSelector,
    'address-capture': AddressCapture,
    'tag-number-selector': TagNumberSelector,
    'insurer-selector': InsurerSelector,
    'customer-selector': CustomerSelector,
    'file-selector': FileSelector,
    'image-selector': ImageSelector,
    'user-selector': UserSelector,
    'switch-selector': SwitchSelector,
    'error-display-component': ErrorDisplayComponent,
    'vue-cropper': VueCropper,
    'send-reminder-control': SendReminderControl,
    'cancel-signature-request-control': CancelSignatureRequestControl,
    'service-loaner-out-of-service-action': InventoryLoanerRemoveServiceAction,
    'license-selector': LicenseSelector,
    'pdf-modal': PdfModalDisplayComponent,
    'vue-signature': vueSignature
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    isButtonMode: {
      type: Boolean,
      default: false
    },
    allowService: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedLoanerStatus: null,
      overridingSubject: null,
      optionalMessage: '',
      address: {
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: ''
      },
      currentLoan: {
        tagNumber: null,
        insurerId: 0,
        policyNumber: '',
        insurer: null,
        returnTime: '',
        cardNumber: '',
        cardType: '',
        cvr: '',
        expiryDate: '',
        nameOnCard: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        state: '',
        city: '',
        country: '',
        postalCode: '',
        gpsAddress: '',
        isDamaged: false,
        damageDescription: '',
        attachments: [],
        loanedById: null,
        roNumber: ''
      },
      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)'
      },
      loadingSign: false,
      isSaving: false,
      isEmailForSigning: false,
      isSigning: false,
      selectedCustomer: null,
      selectedEmployee: null,
      selectedSalesPerson: null,
      selectedAdvisor: null,
      employeeMobile: '',
      employeeEmail: '',
      employeeDriversLicenceNumber: '',
      isNewLoanerEvent: false,
      downloadUrl: '',
      isSignatureActive: false,
      signatureLoanerEventId: 0,
      selectedAddresses: [],
      selectedInsurers: [],
      serverErrors: [],
      serverErrorMessage: '',
      damageFiles: [],
      readOnly: false,
      isPreviewing: false,
      documentSigningRequestData: [],
      tabIndex: 0,
      croppedCustomerImages: [],
      showCropDriverPhotoButton: false,
      cropDriverPhotoActive: false,
      isLoadingPhoto: false,
      isMobile: window.innerWidth <= 700,
      signatureRequest: null,
      showConfirmReminder: false,
      sendingReminder: false,
      cancelSignatureRequest: null,
      showCancelRequest: false,
      sendingCancellationRequest: false,
      driversPhotoFiles: [],
      customerPhotoFiles: [],
      insurancePhotoFiles: [],
      cardPhotoFiles: [],
      employeeDriversPhotoFiles: [],
      selectedState: null,
      backOfDriversPhotoFiles: [],
      isUploadingFile: false,
      fileUploadCount: 0,
      pdfModalFile: null
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapConfigGetters(['definedTypes']),
    ...mapAllStatesGetters(['allStates']),
    requiresServiceAdvisor: function () {
      return this.isServiceLoaner
    },
    isMobileRequired: function () {
      return !this.isTestDrive
    },
    isEmailRequired: function () {
      return !this.isTestDrive
    },
    applicableCvrMask: function () {
      if (!this.currentLoan.cardType || this.currentLoan.cardType !== 'Amex') {
        return '###'
      }
      return '####'
    },
    inventoryOverview: function () {
      let parts = [this.currentInventory.stockNo]
      if (this.currentInventory.name) {
        parts.push(this.currentInventory.name)
      }
      parts.push(this.currentInventory.vinNo)
      return parts.filter((x) => x).join(', ')
    },
    requiresAgreementSigning: function () {
      return this.selectedLoanerStatus === null ? false : this.selectedLoanerStatus.altName === 'SalesLoaner' || this.selectedLoanerStatus.altName === 'ServiceLoaner' || this.selectedLoanerStatus.altName === 'TestDrive'
    },
    lastSignedBorrowersAgreement: function () {
      if (this.currentLoan && (this.currentLoan.borrowAgreementStatus === 10 || this.currentLoan.borrowAgreementStatus === 3)) {
        var allSignedBorrowersAgreements = this.currentLoan.attachments.filter((x) => x.classType === 'SignedLoanAgreement' && x.status === 'Active')
        if (allSignedBorrowersAgreements.length > 0) {
          return allSignedBorrowersAgreements[0].fileLocation
        }
      }
      return ''
    },
    hasLoanerEventAccess: function () {
      let allowedPermissions = ['loanerevent.create']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    hasMoreLoanerEvents: function () {
      return this.currentInventory && this.currentInventory.loanerEvents && this.currentInventory.loanerEvents.length === this.$store.state.config.maxLoanerEvents
    },
    selectedDealer: function () {
      return { id: this.currentInventory.dealerId, name: this.currentInventory.dealerName }
    },
    showReturnedSection: function () {
      return this.readOnly ? this.currentLoan.type === 'Returned' : this.lastLoanEvent !== null && this.lastLoanEvent.type !== 'Returned'
    },
    isReturn: function () {
      return this.selectedLoanerStatus !== null && this.selectedLoanerStatus.altName === 'Returned'
    },
    isServiceLoaner: function () {
      return this.selectedLoanerStatus !== null && this.selectedLoanerStatus.altName === 'ServiceLoaner'
    },
    isSalesLoaner: function () {
      return this.selectedLoanerStatus !== null && this.selectedLoanerStatus.altName === 'SalesLoaner'
    },
    isTestDrive: function () {
      return this.selectedLoanerStatus !== null && this.selectedLoanerStatus.altName === 'TestDrive'
    },
    showCreditCardBox: function () {
      return this.selectedLoanerStatus === null ? false : this.selectedLoanerStatus.altName === 'SalesLoaner' || this.selectedLoanerStatus.altName === 'ServiceLoaner'
    },
    requiresInsuranceDetails: function () {
      return this.selectedLoanerStatus === null ? false : this.selectedLoanerStatus.altName !== 'EmployeeDriver' && this.selectedLoanerStatus.altName !== 'TestDrive'
    },
    isCustomerDriver: function () {
      return this.selectedLoanerStatus === null ? false : !this.isEmployeeDriver
    },
    isEmployeeDriver: function () {
      return this.selectedLoanerStatus === null ? false : this.selectedLoanerStatus.altName === 'EmployeeDriver'
    },
    loanerEventTypeActiveValues: function () {
      return this.showReturnedSection
        ? this.definedTypes.loanerEventType.options.filter((x) => (x.id === this.definedTypes.loanerEventType.Returned))
        : this.definedTypes.loanerEventType.options.filter((x) => (x.id === this.definedTypes.loanerEventType.SalesLoaner ||
        x.id === this.definedTypes.loanerEventType.ServiceLoaner ||
        x.id === this.definedTypes.loanerEventType.TestDrive ||
        x.id === this.definedTypes.loanerEventType.EmployeeDriver))
    },
    lastLoanEvent: function () {
      return this.currentInventory.loanerEvents.length > 0 ? this.currentInventory.loanerEvents[0] : null
    },
    displayType: function () {
      return this.definedTypes.loanerEventType.options.find((x) => (x.name === this.currentLoan.type || x.altName === this.currentLoan.type)).name
    },
    displayClose: function () {
      return this.readOnly ? 'Close' : 'Cancel'
    },
    displaySave: function () {
      return this.selectedLoanerStatus ? (this.selectedLoanerStatus.altName === 'Returned' ? 'Save' : 'Reserve') : 'Save'
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    hasUpdateServiceLoanerServiceAccess: function () {
      let allowedPermissions = ['inventory.modify']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isServiceLoanerServiceable: function () {
      if (this.currentInventory.isServiceLoanerOutOfService) {
        return false
      }
      return this.currentInventory.isServiceLoaner && !this.currentInventory.isServiceLoanerRetired
    },
    isError: function () {
      return this.serverErrorMessage.length > 0 || this.serverErrors.length > 0
    },
    stateRequiredBackOfDriversLicense: function () {
      if (this.selectedState) {
        return this.selectedState.requiresBackOfDriversLicense
      }
      return false
    },
    isPpaClosed: function () {
      return this.currentInventory.type === 'PpaClosed'
    }
  },
  methods: {
    isCancellationDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    isReminderDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    loadData: function () {
      if (this.tabIndex === 0) return
      let inventoryId = this.currentInventory.id
      const searchCriteria = {
        inventoryId: inventoryId,
        dealerId: this.currentDealer.id,
        forInventoryLoaner: true,
        query: '',
        pageSize: this.perPage,
        sortBy: 'sentAt',
        page: this.page
      }
      this.isLoading = true
      documentSigningRequestService.searchByType(searchCriteria).then(response => {
        this.documentSigningRequestData = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          item.reminderSent = false
          item.cancellationSent = false
          this.documentSigningRequestData.push(item)
        })

        this.isLoading = false
      }).catch((error) => {
        this.documentSigningRequestData = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    convertTimeString: function (date, status) {
      if (status !== 0) {
        var d = new Date(date)
        var hour = '' + d.getHours()
        var minute = '' + d.getMinutes()

        if (hour.length < 2) hour = '0' + hour
        if (minute.length < 2) minute = '0' + minute

        return [hour, minute].join(':')
      } else {
        return ''
      }
    },
    formateSignatureType: function (type) {
      return type === 'LeaseReturned' ? 'Lease Returned' : type
    },
    onEditLoanerEvent: function () {
      this.isNewLoanerEvent = false
      this.$router.push({ name: 'EditLoanerEvent', params: { id: this.currentLoan.id } })
    },
    canShowBorrowAgreementStatus: function (loanerEvent) {
      return loanerEvent === null ? false : loanerEvent.type === 'SalesLoaner' || loanerEvent.type === 'ServiceLoaner' || loanerEvent.type === 'TestDrive'
    },
    onDownloadBorrowersAgreement: function () {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.lastSignedBorrowersAgreement)
    },
    onPreviewAgreement: function () {
      this.isPreviewing = true
      var documentUri = process.env.VUE_APP_ROOT_DOC_SITE + 'docs/loaner/preview/'
      if (this.isTestDrive) {
        documentUri += 'test-drive/'
      }
      documentUri += this.currentUser.id
      if (!this.isTestDrive) {
        documentUri += '/' + this.currentInventory.id + '/' + this.selectedLoanerStatus.altName
      }
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?fileName=borrowers-agreement-preview.pdf&url=' + encodeURI(documentUri)
      this.isPreviewing = false
    },
    onShowMoreLoanerEvents: function () {
      this.isLoading = true
      inventoryService.getAllLoanerEventsByInventory(this.currentInventory).then(response => {
        this.currentInventory.loanerEvents.push(...response)
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    getColorClassByLoanerEventStatus: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 0:
          result = 'has-text-danger'
          break
        case 1:
          result = 'has-text-success'
          break
        case 2:
          result = 'has-text-danger'
          break
        case 3:
          result = 'has-text-danger'
          break
        case 4:
          result = 'has-text-danger'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    displayRowType: function (type) {
      let found = this.definedTypes.loanerEventType.options.find((x) => (x.name === type || x.altName === type))
      return (!found) ? '' : found.displayName
    },
    isCreatingNewLoanerEvent: function (state) {
      this.isNewLoanerEvent = state
      this.readOnly = false
      this.setDefaults()
      if (state === true) {
        this.overridingSubject = 'Borrowers Agreement'
        this.optionalMessage = 'Please review and sign the attached borrowers agreement.'
        this.loadDefaults()
      } else if (this.isButtonMode) {
        eventBus.$emit('close-return-modal', false)
      }
    },
    setDefaults: function () {
      this.currentLoan = {
        tagNumber: null,
        insurerId: 0,
        policyNumber: '',
        insurer: null,
        returnTime: '',
        cardNumber: '',
        cardType: '',
        cvr: '',
        expiryDate: '',
        nameOnCard: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        state: '',
        city: '',
        country: '',
        postalcode: '',
        email: '',
        gpsAddress: '',
        damageFiles: [],
        loanedById: null,
        roNumber: ''
      }
      this.selectedCustomer = null
      this.selectedLoanerStatus = this.showReturnedSection ? this.definedTypes.loanerEventType.options.find((x) => (x.id === this.definedTypes.loanerEventType.Returned)) : null
      this.selectedEmployee = null
      this.selectedSalesPerson = this.$store.state.user.currentUser
      this.selectedAdvisor = null
      this.employeeEmail = ''
      this.employeeMobile = ''
      this.driversPhotoFiles = []
      this.insurancePhotoFiles = []
      this.cardPhotoFiles = []
      this.customerPhotoFiles = []
      this.employeeDriversPhotoFiles = []
      this.backOfDriversPhotoFiles = []
      this.damageFiles = []
      if (!this.showReturnedSection && this.currentInventory) {
        if (this.currentInventory.isServiceLoaner === true) {
          this.selectedLoanerStatus = this.definedTypes.loanerEventType.options.find((x) => (x.id === this.definedTypes.loanerEventType.ServiceLoaner))
          if (this.currentInventory.plateNumber && this.currentInventory.plateNumber !== '') {
            this.currentLoan.tagNumber = { id: -1, tagNumber: this.currentInventory.plateNumber }
          }
        }
      }
    },
    onEmailBorrowed: function (signNow, signInPerson) {
      this.serverErrorMessage = ''
      this.validateBeforeSubmitWithCallBack(
        () => {
          var loanedByType = 'Customer'
          var loanedById = this.selectedCustomer ? this.selectedCustomer.id : 0

          if (this.isEmployeeDriver || (this.lastLoanEvent !== null && this.lastLoanEvent.type === 'EmployeeDriver')) {
            loanedByType = 'Employee'
            loanedById = this.selectedEmployee ? this.selectedEmployee.id : 0
            if (this.currentLoan.tagNumber !== null) {
              this.currentLoan.tagNumber.reservedFor = this.selectedEmployee ? this.selectedEmployee.id : null
              this.currentLoan.tagNumber.reserved = true
            }
          }

          if (this.selectedLoanerStatus.altName !== 'EmployeeDriver' && this.currentLoan.tagNumber !== null) {
            if (this.currentLoan.tagNumber !== null) {
              this.currentLoan.tagNumber.reservedFor = null
              this.currentLoan.tagNumber.reserved = false
            }
          }

          if (this.currentLoan.type === 'Returned' && this.selectedLoanerStatus.altName === 'EmployeeDriver') {
            if (this.currentLoan.tagNumber !== null) {
              this.currentLoan.tagNumber.reservedFor = null
              this.currentLoan.tagNumber.reserved = false
            }
          }

          this.isEmailForSigning = signNow && !signInPerson
          this.isSignNow = signNow && signInPerson
          this.isSaving = true
          var loanerEventModel = {
            inventoryId: this.currentInventory.id,
            loanedById: loanedById,
            tagNumber: this.currentLoan.tagNumber ? this.currentLoan.tagNumber : '',
            policyNumber: this.currentLoan.policyNumber || '',
            creditCardType: this.currentLoan.cardType || '',
            creditCardNo: this.currentLoan.cardNumber || '',
            creditCardCvr: this.currentLoan.cvr || '',
            creditCardExpiryDate: this.currentLoan.expiryDate || '',
            creditCardHolderName: this.currentLoan.nameOnCard || '',
            address1: this.address.address1 || '',
            address2: this.address.address2 || '',
            address3: this.address.address3 || '',
            address4: this.address.address4 || '',
            city: this.address.city || '',
            postalCode: this.address.postalCode || '',
            state: this.address.state || '',
            country: this.address.country || '',
            gpsAddress: this.address.gpsAddress || '',
            returnTime: this.currentLoan.returnTime,
            status: 0,
            type: this.selectedLoanerStatus.altName,
            loanedByType: loanedByType,
            insurerId: this.currentLoan.insurer ? this.currentLoan.insurer.id : 0,
            signNow: signNow,
            signInPerson: signInPerson,
            isDamaged: this.currentLoan.isDamaged,
            damageDescription: this.currentLoan.damageDescription || '',
            currentUsage: this.currentInventory.usage,
            attachments: [],
            loanedByEmail: this.selectedCustomer ? this.selectedCustomer.email : (this.selectedEmployee ? this.employeeEmail : ''),
            loanedByPhone: this.selectedCustomer ? this.selectedCustomer.mobile : (this.selectedEmployee ? this.employeeMobile : ''),
            employeeDriversLicenceNumber: this.employeeDriversLicenceNumber,
            agentName: this.currentLoan.agentName || '',
            agentContactNo: this.currentLoan.agentContactNo || '',
            salesPersonId: this.selectedSalesPerson ? this.selectedSalesPerson.id : 0,
            advisorId: this.selectedAdvisor ? this.selectedAdvisor.id : 0,
            roNumber: this.currentLoan.roNumber || ''
          }

          if (!this.showReturnedSection) {
            if (this.isCustomerDriver) {
              let validDriversPhotoFiles = this.driversPhotoFiles.filter((y) => y.status !== 'Deleted')
              if (validDriversPhotoFiles.length > 0) {
                var driversPhotoFile = validDriversPhotoFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
                  return {
                    uploadName: x.name,
                    uniqueId: x.uniqueId
                  }
                })

                loanerEventModel.driversUploadName = driversPhotoFile[0].uploadName
                loanerEventModel.driversUniqueId = driversPhotoFile[0].uniqueId
              } else {
                loanerEventModel.driversUploadName = ''
                loanerEventModel.driversUniqueId = ''
              }

              let validBackOfDriversPhotoFiles = this.backOfDriversPhotoFiles.filter((y) => y.status !== 'Deleted')
              if (validBackOfDriversPhotoFiles.length > 0) {
                var backOfDriversPhotoFile = validBackOfDriversPhotoFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
                  return {
                    uploadName: x.name,
                    uniqueId: x.uniqueId
                  }
                })

                loanerEventModel.backOfDriversUploadName = backOfDriversPhotoFile[0].uploadName
                loanerEventModel.backOfDriversUniqueId = backOfDriversPhotoFile[0].uniqueId
              } else {
                loanerEventModel.backOfDriversUploadName = ''
                loanerEventModel.backOfDriversUniqueId = ''
              }

              let validCustomerFiles = this.customerPhotoFiles.filter((y) => y.status !== 'Deleted')
              if (validCustomerFiles.length > 0) {
                var customerPhotoFile = validCustomerFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
                  return {
                    uploadName: x.name,
                    uniqueId: x.uniqueId
                  }
                })

                loanerEventModel.uploadName = customerPhotoFile[0].uploadName
                loanerEventModel.uniqueId = customerPhotoFile[0].uniqueId
              } else {
                loanerEventModel.uploadName = ''
                loanerEventModel.uniqueId = ''
              }
            }

            if (this.isEmployeeDriver) {
              let validDriversPhotoFiles = this.employeeDriversPhotoFiles.filter((y) => y.status !== 'Deleted')
              if (validDriversPhotoFiles.length > 0) {
                var employeeDriversPhotoFile = validDriversPhotoFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
                  return {
                    uploadName: x.name,
                    uniqueId: x.uniqueId
                  }
                })

                loanerEventModel.employeeDriversUploadName = employeeDriversPhotoFile[0].uploadName
                loanerEventModel.employeeDriversUniqueId = employeeDriversPhotoFile[0].uniqueId
              } else {
                loanerEventModel.employeeDriversUploadName = ''
                loanerEventModel.employeeDriversUniqueId = ''
              }
            }

            if (this.isCustomerDriver && !this.isTestDrive) {
              let validInsuranceFiles = this.insurancePhotoFiles.filter((y) => y.status !== 'Deleted')
              if (validInsuranceFiles.length > 0) {
                var insurancePhotoFile = validInsuranceFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
                  return {
                    uploadName: x.name,
                    uniqueId: x.uniqueId
                  }
                })

                loanerEventModel.insuranceUploadName = insurancePhotoFile[0].uploadName
                loanerEventModel.insuranceUniqueId = insurancePhotoFile[0].uniqueId
              } else {
                loanerEventModel.insuranceUploadName = ''
                loanerEventModel.insuranceUniqueId = ''
              }
            }

            if (this.showCreditCardBox) {
              let validCardPhotoFiles = this.cardPhotoFiles.filter((y) => y.status !== 'Deleted')
              if (validCardPhotoFiles.length > 0) {
                var cardPhotoFile = validCardPhotoFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
                  return {
                    uploadName: x.name,
                    uniqueId: x.uniqueId
                  }
                })

                loanerEventModel.cardUploadName = cardPhotoFile[0].uploadName
                loanerEventModel.cardUniqueId = cardPhotoFile[0].uniqueId
              } else {
                loanerEventModel.cardUploadName = ''
                loanerEventModel.cardUniqueId = ''
              }
            }
          }

          if (this.showReturnedSection) {
            if (this.currentLoan.isDamaged && this.damageFiles.length > 0) {
              var damageFile = this.damageFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
                return {
                  uploadName: x.name,
                  uploadSize: x.size,
                  uniqueId: x.uniqueId,
                  type: x.type,
                  classType: 'Damage'
                }
              })
              loanerEventModel.attachments.push(...damageFile)
            }
          }

          inventoryLoanerEventService.save(loanerEventModel).then(response => {
            if (response.errors) {
              this.serverErrors = response.errors
              this.isSaving = false
            } else {
              if (response.inventoryLoanerEvent) {
                eventBus.$emit('inventory-changed', [
                  {
                    id: response.inventoryLoanerEvent.inventoryId,
                    loanerStatusType: response.inventoryLoanerEvent.typeId
                  }
                ])
                this.currentInventory.loanerEvents.unshift(response.inventoryLoanerEvent)
                this.isNewLoanerEvent = false
                if (signInPerson) {
                  this.isSignatureActive = true
                  this.signatureLoanerEventId = response.inventoryLoanerEvent.id
                  // Will do this when using HelloSign for in person signing
                  // this.$router.push({name: 'Signature', params: {clientId: response.inventoryLoanerEvent.signInPersonId, signatureUrl: response.inventoryLoanerEvent.signInPersonUrl, redirectName: 'ListInventory'}})
                } else {
                  eventBus.$emit('close-return-modal', true)
                }
              }
              this.isSaving = false
            }
          }).then(() => {
            let userStatusChangeRequest = {
              userId: this.selectedSalesPerson.id,
              isAvailable: true,
              reason: ''
            }
            userService.changeUsersAvailabilityStatus(userStatusChangeRequest)
          })
            .catch((error) => {
              this.isSaving = false
              this.serverErrorMessage = error.message
            })
        }
      )
    },
    onRowSelected: function (item, previousItem) {
      this.readOnly = true
      this.currentLoan = { ...item }
      this.setAddress(item)
      this.selectedLoanerStatus = this.definedTypes.loanerEventType.options.find((x) => (x.id === item.typeId))
      if (item.type !== 'EmployeeDriver') {
        this.selectedCustomer = { id: item.loanedById, fullName: item.loanedByFullName, mobile: item.loanedByPhone, email: item.loanedByEmail, dealerId: this.selectedDealer }
      } else {
        this.selectedEmployee = { id: item.loanedById, fullName: item.loanedByFullName, mobile: item.loanedByPhone, email: item.loanedByEmail, dealerId: this.selectedDealer }
      }

      if (item.salesPersonId) {
        this.selectedSalesPerson = {
          id: item.salesPersonId,
          fullName: item.salesPersonFullName
        }
      }
      this.employeeMobile = item.loanedByPhone
      this.employeeEmail = item.loanedByEmail
      this.currentLoan.insurer = { id: item.insurerId, name: item.insurerName, dealerId: this.selectedDealer }
      this.customerPhotoFiles = []
      this.driversPhotoFiles = []
      this.backOfDriversPhotoFiles = []
      this.employeeDriversPhotoFiles = []

      if (this.currentLoan.driversFileLocation) {
        var driversPhotoFile = {
          loaded: true,
          imageData: this.currentLoan.driversFileLocation,
          fileName: this.currentLoan.driversFileName,
          fileLocation: this.currentLoan.driversFileLocation,
          name: this.currentLoan.driversImageName,
          fileType: this.getFileType(this.currentLoan.driversFileLocation),
          status: 'Active'
        }

        this.driversPhotoFiles.push(driversPhotoFile)
      }
      if (this.currentLoan.backOfDriversFileLocation) {
        var backOfDriversPhotoFile = {
          loaded: true,
          imageData: this.currentLoan.backOfDriversFileLocation,
          fileName: this.currentLoan.backOfDriversFileName,
          fileLocation: this.currentLoan.backOfDriversFileLocation,
          name: this.currentLoan.backOfDriversImageName,
          fileType: this.getFileType(this.currentLoan.backOfDriversFileLocation),
          status: 'Active'
        }

        this.backOfDriversPhotoFiles.push(backOfDriversPhotoFile)
      }

      if (this.currentLoan.fileLocation) {
        var customerPhotoFile = {
          loaded: true,
          imageData: this.currentLoan.fileLocation,
          fileName: this.currentLoan.fileName,
          fileLocation: this.currentLoan.fileLocation,
          name: this.currentLoan.imageName,
          fileType: this.getFileType(this.currentLoan.fileLocation),
          status: 'Active'
        }

        this.customerPhotoFiles.push(customerPhotoFile)
      }

      if (this.currentLoan.employeeDriversFileLocation) {
        var employeeDriversPhotoFile = {
          loaded: true,
          imageData: this.currentLoan.employeeDriversFileLocation,
          fileName: this.currentLoan.employeeDriversFileName,
          fileLocation: this.currentLoan.employeeDriversFileLocation,
          name: this.currentLoan.employeeDriversImageName,
          fileType: this.getFileType(this.currentLoan.employeeDriversFileLocation),
          status: 'Active'
        }

        this.employeeDriversPhotoFiles.push(employeeDriversPhotoFile)
      }

      this.currentInventory.attachments.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        x.fileType = this.getFileType(x.fileLocation)
        if (x.classType === 'Damage' && x.forSourceId === item.id) {
          this.damageFiles.push(x)
        }
      })
      this.isNewLoanerEvent = true
    },
    minimumUsageAllowed: function () {
      if (this.currentInventory && this.currentInventory.vinUsageHistory && this.currentInventory.vinUsageHistory.length > 0) {
        return this.currentInventory.vinUsageHistory[this.currentInventory.vinUsageHistory.length - 1].usage
      }
      return 0
    },
    onSign () {
      this.loadingSign = true
      var png = this.$refs.signature.save()

      var generatePdfModal = {
        signatureString: png,
        inventoryLoanerEventId: this.signatureLoanerEventId,
        requestedById: this.currentUser.id
      }

      inventoryLoanerEventService.generatePdfWithSignature(generatePdfModal).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
          this.loadingSign = false
        } else {
          this.currentInventory.loanerEvents[0].borrowAgreementStatus = 10
          if (this.selectedLoanerStatus && this.selectedLoanerStatus.altName === 'EmployeeDriver') {
            this.isSignatureActive = false
            this.loadingSign = false
          } else {
            this.onPreview(response.loanerPdfUrl)
            this.isSignatureActive = false
            this.loadingSign = false
          }
        }
      }).catch((error) => {
        this.loadingSign = false
        this.serverErrorMessage = error.message
      })
    },
    onClearSign () {
      this.$refs.signature.clear()
    },
    onUndoSign () {
      this.$refs.signature.undo()
    },
    onCancelSignNow () {
      this.isSignatureActive = false
      this.loadingSign = false
      eventBus.$emit('close-return-modal', true)
    },
    fromDataURL (url) {
      this.$refs.signature.fromDataURL('data:image/png;base64,iVBORw0K...')
    },
    closePdf () {
      eventBus.$emit('close-return-modal', true)
    },
    onPrint: function () {
      var pdfControls = this.$refs.pdfViewer
      if (pdfControls) {
        if (Array.isArray(pdfControls) && pdfControls.length > 0) {
          pdfControls[0].print(100)
        } else {
          pdfControls.print(100)
        }
      } else {
        this.failedToast('Printing support is not available in this browser.')
      }
    },
    onDownloadFile: function () {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.downloadUrl)
    },
    setAddress: function (source) {
      this.address.address1 = source.address1
      this.address.address2 = source.address2
      this.address.address3 = source.address3
      this.address.address4 = source.address4
      this.address.city = source.city
      this.address.country = source.country
      this.address.state = source.state
      this.address.postalCode = source.postalCode
      this.address.gpsAddress = source.gpsAddress
    },
    loadDefaults: function () {
      if (this.showReturnedSection) {
        this.currentLoan = { ...this.lastLoanEvent }
        if (this.lastLoanEvent.type !== 'EmployeeDriver') {
          this.selectedCustomer = { id: this.lastLoanEvent.loanedById, fullName: this.lastLoanEvent.loanedByFullName, mobile: '', email: '' }
        } else {
          this.selectedEmployee = { id: this.lastLoanEvent.loanedById, fullName: this.lastLoanEvent.loanedByFullName, mobile: '', email: '' }
        }

        this.currentLoan.insurer = { id: this.lastLoanEvent.insurerId, name: this.lastLoanEvent.insurerName }
        this.setAddress(this.lastLoanEvent)
        this.customerPhotoFiles = []
        this.driversPhotoFiles = []
        this.employeeDriversPhotoFiles = []
        this.backOfDriversPhotoFiles = []

        if (this.currentLoan.driversFileLocation) {
          var driversPhotoFile = {
            loaded: true,
            imageData: this.currentLoan.driversFileLocation,
            fileName: this.currentLoan.driversFileName,
            fileLocation: this.currentLoan.driversFileLocation,
            name: this.currentLoan.driversImageName,
            fileType: this.getFileType(this.currentLoan.driversFileLocation),
            status: 'Active'
          }

          this.driversPhotoFiles.push(driversPhotoFile)
        }

        if (this.currentLoan.backOfDriversFileLocation) {
          var backOfDriversPhotoFile = {
            loaded: true,
            imageData: this.currentLoan.backOfDriversFileLocation,
            fileName: this.currentLoan.backOfDriversFileName,
            fileLocation: this.currentLoan.backOfDriversFileLocation,
            name: this.currentLoan.backOfDriversImageName,
            fileType: this.getFileType(this.currentLoan.backOfDriversFileLocation),
            status: 'Active'
          }

          this.backOfDriversPhotoFiles.push(backOfDriversPhotoFile)
        }

        if (this.currentLoan.fileLocation) {
          var customerPhotoFile = {
            loaded: true,
            imageData: this.currentLoan.fileLocation,
            fileName: this.currentLoan.fileName,
            fileLocation: this.currentLoan.fileLocation,
            name: this.currentLoan.imageName,
            fileType: this.getFileType(this.currentLoan.fileLocation),
            status: 'Active'
          }

          this.customerPhotoFiles.push(customerPhotoFile)
        }

        if (this.currentLoan.employeeDriversFileLocation) {
          var employeeDriversPhotoFile = {
            loaded: true,
            imageData: this.currentLoan.employeeDriversFileLocation,
            fileName: this.currentLoan.employeeDriversFileName,
            fileLocation: this.currentLoan.employeeDriversFileLocation,
            name: this.currentLoan.employeeDriversImageName,
            fileType: this.getFileType(this.currentLoan.employeeDriversFileLocation),
            status: 'Active'
          }

          this.employeeDriversPhotoFiles.push(employeeDriversPhotoFile)
        }
      }
    },
    onCancelRequest: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.cancelSignatureRequest = signatureRequest
        this.onShowCancellationRequestModal()
      } else {
        this.failedToast('Signature request cannot be cancelled at this point.')
      }
    },
    onRequestReminder: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.signatureRequest = signatureRequest
        this.onShowReminderModal()
      } else {
        this.failedToast('Signature request reminder cannot be sent at this point.')
      }
    },
    sentDate: function (sentAt) {
      return sentAt ? this.$options.filters.formatDateOnly(sentAt) : 'Not Sent'
    },
    sentTime: function (sentAt, signingStatus) {
      return sentAt ? this.convertTimeString(sentAt, signingStatus) : 'Not Sent'
    },
    onSaveCroppedDriverPhoto: function () {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        var fileName = 'cropped_photo_' + new Date().getTime() + '.png'
        var file = new File([blob], fileName, {type: 'image/png', lastModified: new Date()})
        this.croppedCustomerImages = []
        this.croppedCustomerImages.push(file)
      })
      this.showCropDriverPhotoButton = false
      this.cropDriverPhotoActive = false
    },
    cropDriverPhoto: function () {
      this.uploadComplete()
      let activeCustomerPhotoFiles = this.customerPhotoFiles.filter((x) => x.status === 'Active')
      let activeDriverPhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
      if (activeCustomerPhotoFiles.length === 0 && activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (util.fileIsImage(fileName)) {
          this.cropDriverPhotoActive = true
          this.selectedFile = activeDriverPhotoFiles[0].imageData
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(this.selectedFile)
          }
        }
      }
    },
    cropEmployeeDriverPhoto: function () {
      this.uploadComplete()
      let activeEmployeeDriverPhotoFiles = this.employeeDriversPhotoFiles.filter((x) => x.status === 'Active')
      if (activeEmployeeDriverPhotoFiles.length > 0) {
        let fileName = activeEmployeeDriverPhotoFiles[0].fileName ? activeEmployeeDriverPhotoFiles[0].fileName : activeEmployeeDriverPhotoFiles[0].name
        if (util.fileIsImage(fileName)) {
          this.cropDriverPhotoActive = true
          this.selectedFile = activeEmployeeDriverPhotoFiles[0].imageData
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(this.selectedFile)
          }
        }
      }
    },
    showCropDriversPhotoModal: function () {
      let activeCustomerPhotoFiles = this.customerPhotoFiles.filter((x) => x.status === 'Active')
      if (activeCustomerPhotoFiles.length === 0 && this.driversPhotoFiles.length > 0) {
        let imageData = this.driversPhotoFiles[0].imageData
        var pattern = /^((http|https):\/\/)/
        if (pattern.test(imageData)) {
          this.cropDriverPhotoActive = true
          let fileName = this.driversPhotoFiles[0].fileName
          let that = this
          let changedConfig = baseService.defaultConfig
          changedConfig.responseType = 'blob'
          this.isLoadingPhoto = true
          axios.get('/document/downloadfile?url=' + imageData + '&fileName=' + fileName, changedConfig)
            .then(function (response) {
              var reader = new window.FileReader()
              reader.readAsDataURL(response.data)
              reader.onload = function () {
                that.isLoadingPhoto = false
                var imageDataUrl = reader.result
                that.selectedFile = imageDataUrl
                if (that.$refs.cropper) {
                  that.$refs.cropper.replace(that.selectedFile)
                }
                that.showCropDriverPhotoButton = false
              }
            }).catch(function (error) {
              console.log(error)
              this.isLoadingPhoto = false
            }).then(function () {
              changedConfig.responseType = 'json'
            })
        } else {
          this.cropDriverPhoto()
        }
      }
    },
    showCropEmployeeDriversPhotoModal: function () {
      if (this.employeeDriversPhotoFiles.length > 0) {
        let imageData = this.employeeDriversPhotoFiles[0].imageData
        var pattern = /^((http|https):\/\/)/
        if (pattern.test(imageData)) {
          this.cropDriverPhotoActive = true
          let fileName = this.employeeDriversPhotoFiles[0].fileName
          let that = this
          let changedConfig = baseService.defaultConfig
          changedConfig.responseType = 'blob'
          this.isLoadingPhoto = true
          axios.get('/document/downloadfile?url=' + imageData + '&fileName=' + fileName, changedConfig)
            .then(function (response) {
              var reader = new window.FileReader()
              reader.readAsDataURL(response.data)
              reader.onload = function () {
                that.isLoadingPhoto = false
                var imageDataUrl = reader.result
                that.selectedFile = imageDataUrl
                if (that.$refs.cropper) {
                  that.$refs.cropper.replace(that.selectedFile)
                }
                that.showCropDriverPhotoButton = false
              }
            }).catch(function (error) {
              console.log(error)
              this.isLoadingPhoto = false
            }).then(function () {
              changedConfig.responseType = 'json'
            })
        } else {
          this.cropDriverPhoto()
        }
      }
    },
    closeCropDriverPhoto: function () {
      this.cropDriverPhotoActive = false
    },
    onShowReminderModal: function () {
      this.isNewLoanerEvent = false
      this.showConfirmReminder = true
    },
    onHideReminderModal: function () {
      this.showConfirmReminder = false
      this.isNewLoanerEvent = true
      this.signatureRequest = null
    },
    sendReminder: function () {
      if (!this.sendingReminder) {
        this.sendingReminder = true
        documentSigningRequestService.sendReminder(this.signatureRequest).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.signatureRequest.reminderSent = true
            this.successToast('Signature request reminder has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideReminderModal()
          this.sendingReminder = false
        })
      }
    },
    onShowCancellationRequestModal: function () {
      this.isNewLoanerEvent = false
      this.showCancelRequest = true
    },
    onHideCancellationRequestModal: function () {
      this.showCancelRequest = false
      this.isNewLoanerEvent = true
      this.cancelSignatureRequest = null
    },
    sendCancellationRequest: function () {
      if (!this.sendingCancellationRequest) {
        this.sendingCancellationRequest = true
        documentSigningRequestService.cancelSignatureRequest(this.cancelSignatureRequest.signatureRequestId).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.cancelSignatureRequest.cancellationSent = true
            this.successToast('Signature cancellation request has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideCancellationRequestModal()
          this.sendingCancellationRequest = false
        })
      }
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    },
    onPreview: function (loanerPdfUrl) {
      var extractedFileName = loanerPdfUrl.split('/').pop()
      let docUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(loanerPdfUrl)
      docUrl = encodeURI(docUrl)
      this.pdfModalFile = {
        name: 'Borrower Agreement',
        src: docUrl,
        fileName: extractedFileName,
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
      this.workingState = ''
      this.bulkPaymentControlActive = false
      this.isReceiptDownloading = false
    },
    onPreviewClosed: function () {
      this.closePdf()
      this.receiptSelectedPdf = null
    }
  },
  watch: {
    selectedEmployee: function (newValue, oldValue) {
      if (newValue) {
        this.employeeEmail = newValue.email
        this.employeeMobile = newValue.mobile

        userService.single(newValue.id).then(response => {
          var user = {
            ...response.user
          }

          this.employeeDriversLicenceNumber = user.driverLicenseNumber
          this.currentLoan.employeeDriversPhotoFiles = []
          if (user.driversFileLocation && !this.showReturnedSection) {
            var driversPhotoFile = {
              loaded: true,
              imageData: user.driversFileLocation,
              fileName: user.driversFileName,
              fileLocation: user.driversFileLocation,
              name: user.driversImageName,
              fileType: this.getFileType(user.driversFileName),
              status: 'Active'
            }
            this.currentLoan.employeeDriversPhotoFiles.push(driversPhotoFile)
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
        })
      }
    },
    selectedCustomer: function (newValue, oldValue) {
      if (newValue) {
        if (newValue.id) {
          customerService.single(newValue.id).then(response => {
            var customer = {
              ...response.customer
            }

            this.driversPhotoFiles = []
            this.insurancePhotoFiles = []
            this.customerPhotoFiles = []
            if (customer.driversFileLocation && !this.showReturnedSection) {
              var driversPhotoFile = {
                loaded: true,
                imageData: customer.driversFileLocation,
                fileName: customer.driversFileName,
                fileLocation: customer.driversFileLocation,
                name: customer.driversImageName,
                fileType: this.getFileType(customer.driversFileName),
                status: 'Active'
              }
              this.driversPhotoFiles.push(driversPhotoFile)
            }

            if (customer.backOfDriversFileLocation && !this.showReturnedSection) {
              var backOfDriversPhotoFile = {
                loaded: true,
                imageData: customer.backOfDriversFileLocation,
                fileName: customer.backOfDriversFileName,
                fileLocation: customer.backOfDriversFileLocation,
                name: customer.backOfDriversImageName,
                fileType: this.getFileType(customer.backOfDriversFileName),
                status: 'Active'
              }
              this.backOfDriversPhotoFiles.push(backOfDriversPhotoFile)
            }

            if (customer.driverLicenseState) {
              let usState = this.allStates.find((x) => x.name === customer.driverLicenseState)
              this.selectedState = usState
            }

            if (customer.fileLocation && !this.showReturnedSection) {
              var photoFile = {
                loaded: true,
                imageData: customer.fileLocation,
                fileName: customer.fileName,
                fileLocation: customer.fileLocation,
                name: customer.imageName,
                fileType: this.getFileType(customer.fileName),
                status: 'Active'
              }

              this.customerPhotoFiles.push(photoFile)
            }

            if (this.readOnly) {
              newValue.mobile = customer.mobile
              newValue.email = customer.email
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
          })
        }
      }
    },
    driversPhotoFiles: {
      handler (newValue, oldValue) {
        if (this.customerPhotoFiles) {
          let activeCustomerPhotoFiles = this.customerPhotoFiles.filter((x) => x.status === 'Active')
          let activeDriverPhotoFiles = newValue.filter((x) => x.status === 'Active')
          if ((activeCustomerPhotoFiles.length === 0) && (activeDriverPhotoFiles.length > 0)) {
            let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
            this.showCropDriverPhotoButton = util.fileIsImage(fileName)
          } else {
            this.showCropDriverPhotoButton = false
          }
        } else {
          this.showCropDriverPhotoButton = false
        }
      },
      deep: true
    },
    employeeDriversPhotoFiles: {
      handler (newValue, oldValue) {
        if (newValue) {
          let activeDriverPhotoFiles = newValue.filter((x) => x.status === 'Active')
          if (activeDriverPhotoFiles.length > 0) {
            let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
            this.showCropDriverPhotoButton = util.fileIsImage(fileName)
          } else {
            this.showCropDriverPhotoButton = false
          }
        } else {
          this.showCropDriverPhotoButton = false
        }
      },
      deep: true
    },
    customerPhotoFiles: {
      handler (newValue, oldValue) {
        let activeCustomerPhotoFiles = newValue.filter((x) => x.status === 'Active')
        let activeDriverPhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
        if (activeDriverPhotoFiles.length === 0 || (activeCustomerPhotoFiles.length > 0)) {
          this.showCropDriverPhotoButton = false
        } else {
          let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
          this.showCropDriverPhotoButton = util.fileIsImage(fileName)
        }
      },
      deep: true
    }
  },
  mounted: function () {
    if (this.isButtonMode) {
      this.isCreatingNewLoanerEvent(true)
    }
  }
}
</script>

<style scoped>

  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .status-icon {
    margin-right:5px;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
  }

  .main-dialog-footer {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    height:auto;
  }

  @media screen and (max-width: 500px) {
    .main-dialog-footer {
      height:120px;
    }
  }

  .modal-card-body {
    height: 100% !important;
  }

  .read-only-field {
    height: 3em;
    display: inline-flex;
    align-items: center
  }

  .modal-sign-body {
    position:relative;
    max-height: 150px !important;
    height: 150px !important;
    max-width: 500px;
    width: 500px;
    overflow-y: auto;
    background: white;
  }

  @media screen and (max-width: 500px) {
    .modal-sign-body {
      position:relative;
      overflow-y: auto;
      background: white;
    }
  }

  .loaner-event-row {
    text-align: left;
    vertical-align:middle;
  }

  .loaner-event-column {
    text-align: left;
    vertical-align:middle;
  }

  #crop-card {
    overflow: auto;
    min-width: 800px !important;
    max-width: 800px !important;
    width: 800px !important;
  }

  #crop-card-body {
    max-height: 575px !important;
    min-height: 575px !important;
    height: 575px !important;
  }

  .loaner-event-container-modal {
    .modal-info-body {
      position:relative;
      max-height: 65vh;
      height: 65vh;
      width: 960px;
      overflow-y: auto;
    }
  }

</style>
