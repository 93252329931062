<template>
  <div class="is-inline-block" v-if="hasAccessoryEventAccess">
    <div class="is-inline-block" v-if="hasAccessoryEventAccess">
      <div class="quick-action">
        <button class="button" type="button" @click="onAddAccessoryEvent()" title="Click to add a new accessory event." >
          <b-icon pack="fal" icon="plus" custom-size="fa-lg"></b-icon>
        </button>
      </div>
    </div>
    <portal to="global-modal-portal" v-if="isAddingAccessoryEvent" :order="1">
      <b-modal :active.sync="isAddingAccessoryEvent" scroll="keep" :has-modal-card="true">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{newAccessoryEventTitle}}</p>
          </header>
          <section class="modal-card-body is-paddingless" style="max-height: calc(100vh - 220px);">
            <form data-vv-scope="accessory-event-form" class="view-container">
              <error-display-control :serverErrors="serverErrors || []" :serverErrorMessage="serverErrorMessage || ''"></error-display-control>
              <div class="box">
                <div class="columns is-mobile is-multiline">
                  <div class="column is-12">
                    <label class="label">Vehicle</label>
                    <div class="field" v-html="inventoryOverview"></div>
                  </div>
                  <div class="column is-6">
                    <label class="label">Stock No</label>
                    <div class="field">
                      <a @click.stop.prevent="onStockNoClick(currentInventory)">
                        <span class="has-text-weight-semibold">{{ currentInventory.stockNo }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="column is-6">
                    <label class="label">Vin No</label>
                    <div class="field">
                      {{currentInventory.vinNo}}
                    </div>
                  </div>
                  <div class="column is-12">
                    <label class="label">Action</label>
                    <div class="title is-size-6" :class="getColorClassByAccessoryStatus(selectedAccessoryEventType ? selectedAccessoryEventType.id : 0)">
                      {{currentAccessoryAction}}
                    </div>
                  </div>
                  <div class="column is-12" v-if="isCheckOut">
                    <user-selector :selectedDealer="currentDealer" v-model="selectedGivenToUser" validateAs="given to" label="Given To" :required="true"></user-selector>
                    <span v-show="errors.has('accessory-event-form.selectedGivenToUser')" class="help is-danger"><i v-show="errors.has('accessory-event-form.selectedGivenToUser')" class="fas fa-exclamation-triangle"></i> {{ errors.first('accessory-event-form.selectedGivenToUser') }}</span>
                  </div>
                </div>
                <div class="columns is-multiline is-accessory-options">
                  <div class="column is-6">
                    <button-list-selector :enabled="true" scope="accessory-event-form" label="Keys" validateAs="Keys Status" :required="true" v-model="selectedKeyStatus" :availableValues="availableKeyStatus"></button-list-selector>
                    <span v-show="errors.has('accessory-event-form.selectedKeyStatus')" class="help is-danger"><i v-show="errors.has('accessory-event-form.selectedKeyStatus')" class="fas fa-exclamation-triangle"></i> {{ errors.first('accessory-event-form.selectedKeyStatus') }}</span>
                  </div>
                  <div class="column is-6">
                    <button-list-selector :enabled="true" scope="accessory-event-form" label="Books" validateAs="Books Type" :required="true" v-model="selectedBooksStatus" :availableValues="availableBooksStatus"></button-list-selector>
                    <span v-show="errors.has('accessory-event-form.selectedBooksStatus')" class="help is-danger"><i v-show="errors.has('accessory-event-form.selectedBooksStatus')" class="fas fa-exclamation-triangle"></i> {{ errors.first('accessory-event-form.selectedBooksStatus') }}</span>
                  </div>
                  <div class="column is-12">
                    <button-list-selector :enabled="true" scope="accessory-event-form" label="Car Cover" validateAs="car Cover Status" :required="true" v-model="selectedCarCoverStatus" :availableValues="availableCarCoverStatus"></button-list-selector>
                    <span v-show="errors.has('accessory-event-form.selectedCarCoverStatus')" class="help is-danger"><i v-show="errors.has('accessory-event-form.selectedCarCoverStatus')" class="fas fa-exclamation-triangle"></i> {{ errors.first('accessory-event-form.selectedCarCoverStatus') }}</span>
                  </div>
                  <div class="column is-12">
                    <button-list-selector :enabled="true" scope="accessory-event-form" label="Battery Charger" validateAs="Battery Charger Status" :required="true" v-model="selectedBatteryChargerStatus" :availableValues="availableBatteryChargerStatus"></button-list-selector>
                    <span v-show="errors.has('accessory-event-form.selectedBatteryChargerStatus')" class="help is-danger"><i v-show="errors.has('accessory-event-form.selectedBatteryChargerStatus')" class="fas fa-exclamation-triangle"></i> {{ errors.first('accessory-event-form.selectedBatteryChargerStatus') }}</span>
                  </div>
                </div>
                <div class="columns" v-if="isCheckIn">
                  <div class="column is-12">
                    <accessory-bin-selector
                      label="Stored In Bins"
                      scope="accessory-event-form"
                      validateAs="stored in bins"
                      :required="true"
                      v-model="selectedBins"
                      placeholder="Select 1 or more storage bins">
                    </accessory-bin-selector>
                    <span v-show="errors.has('accessory-event-form.selectedBins')" class="help is-danger"><i v-show="errors.has('accessory-event-formselectedBins')" class="fas fa-exclamation-triangle"></i> {{ errors.first('accessory-event-form.selectedBins') }}</span>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }" @click="onSave()" type="button">Save</button>
            <button class="button is-danger" @click="onCancel()" type="button">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import inventoryService from '@/services/inventoryService'
import UserSelector from '@/components/generic/UserSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import AccesoryBinSelector from '@/components/generic/AccessoryBinSelector'
import ErrorDisplayControl from '@/components/generic/ErrorDisplayControl'
import formattingMixin from '@/mixins/inventory/formatting'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'AccessoryEventCapture',
  mixins: [validateBeforeSubmitMixin, formattingMixin],
  components: {
    'user-selector': UserSelector,
    'error-display-control': ErrorDisplayControl,
    'accessory-bin-selector': AccesoryBinSelector,
    'button-list-selector': ButtonListSelector
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    accessoryEvent: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      isAddingAccessoryEvent: false,
      selectedGivenToUser: null,
      selectedAccessoryEventType: null,
      selectedKeyStatus: null,
      selectedBooksStatus: null,
      selectedCarCoverStatus: null,
      selectedBatteryChargerStatus: null,
      selectedBins: [],
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    availableKeyStatus: function () {
      return [
        {
          id: null,
          name: 'None',
          altName: 'None',
          ico: 'fas fa-check has-text-danger'
        },
        ...this.definedTypes.keyOptions
      ]
    },
    availableBooksStatus: function () {
      return [
        ...this.definedTypes.bookTrackingOptions
      ]
    },
    availableCarCoverStatus: function () {
      return [
        ...this.definedTypes.carCoverTrackingOptions
      ]
    },
    availableBatteryChargerStatus: function () {
      return [
        ...this.definedTypes.batteryChargerTrackingOptions
      ]
    },
    currentAccessoryAction: function () {
      return this.selectedAccessoryEventType ? this.selectedAccessoryEventType.actionName : ''
    },
    isViewMode: function () {
      return this.accessoryEvent !== null
    },
    inventoryOverview: function () {
      if (!this.currentInventory) {
        return ''
      }
      var result = ''
      result = this.currentInventory.stockNo
      result += '<br /> ' + this.currentInventory.name
      if (this.currentInventory.usage) {
        result += '<br /> ' + new Intl.NumberFormat().format(this.currentInventory.usage) + ' miles'
      } else {
        result += '<br /> Milage not captured.'
      }
      var exteriorColor = this.currentInventory.attributes.find((x) => x.name === 'ExteriorColor')
      var interiorColor = this.currentInventory.attributes.find((x) => x.name === 'InteriorColor')
      if (exteriorColor && exteriorColor.value) {
        result += '<br /> Ext. Color (' + exteriorColor.value + ')'
      }
      if (interiorColor && interiorColor.value) {
        result += '<br /> Int. Color (' + interiorColor.value + ')'
      }

      return result
    },
    newAccessoryEventTitle: function () {
      return this.isCheckIn ? 'Accessory Check In' : 'Accessory Check Out'
    },
    hasAccessoryEventAccess: function () {
      let allowedPermissions = ['inventoryaccessory.create']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    lastRecordedEvent: function () {
      return this.currentInventory.accessoryEvents.length > 0 ? this.currentInventory.accessoryEvents[0] : null
    },
    isCheckOut: function () {
      if (this.lastRecordedEvent !== null) {
        return this.lastRecordedEvent.type === 'CheckedIn'
      }
      return false
    },
    isCheckIn: function () {
      if (this.lastRecordedEvent !== null) {
        return this.lastRecordedEvent.type === 'CheckedOut'
      }
      return true
    },
    allowedAccessoryEventTypes: function () {
      if (this.isCheckIn) {
        return this.definedTypes.accessoryEventType.options.filter((x) => x.id === this.definedTypes.accessoryEventType.CheckedIn)
      }
      return this.definedTypes.accessoryEventType.options.filter((x) => x.id === this.definedTypes.accessoryEventType.CheckedOut)
    }
  },
  methods: {
    onAddAccessoryEvent: function () {
      this.errors.clear('accessory-event-form')
      this.isAddingAccessoryEvent = true
      this.setDefaults()
    },
    onCancel: function () {
      this.setDefaults()
      this.isAddingAccessoryEvent = false
    },
    onSave: function () {
      if (this.isCheckOut && this.selectedGivenToUser === null) {
        this.errors.items.push({field: 'selectedGivenToUser', scope: 'accessory-event-form', msg: 'The given to field is required'})
        return
      }
      if (this.isCheckIn && (!this.selectedBins || this.selectedBins.length === 0)) {
        this.errors.items.push({field: 'selectedBins', scope: 'accessory-event-form', msg: 'Please select 1 or more storage bins'})
        return
      }
      this.$validator.validateAll('accessory-event-form').then((result) => {
        if (result) {
          var inventoryAccessoryModel = {
            inventoryId: this.currentInventory.id,
            userId: this.selectedGivenToUser ? this.selectedGivenToUser.id : 0,
            typeId: this.selectedAccessoryEventType.id,
            keyStatus: this.selectedKeyStatus ? this.selectedKeyStatus.id : null,
            booksStatus: this.selectedBooksStatus ? this.selectedBooksStatus.id : null,
            carCoverStatus: this.selectedCarCoverStatus ? this.selectedCarCoverStatus.id : null,
            batteryChargerStatus: this.selectedBatteryChargerStatus ? this.selectedBatteryChargerStatus.id : null,
            accessoryBins: this.selectedBins,
            status: 'Active'
          }

          this.isSaving = true
          this.serverErrorMessage = ''
          this.serverErrors = []
          inventoryService.saveAccessoryEvent(inventoryAccessoryModel).then(response => {
            this.isSaving = false
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              eventBus.$emit('inventory-changed', [response.inventory])
              this.currentInventory.accessoryEvents = response.inventory.accessoryEvents
              this.isAddingAccessoryEvent = false
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      })
    },
    setDefaults: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.selectedBins = []
      if (this.currentInventory.keyStatus) {
        if (this.currentInventory.keyStatus === 'Two') {
          this.selectedKeyStatus = this.definedTypes.keyOptions.find((x) => (x.id === 1))
        } else {
          this.selectedKeyStatus = {
            id: null,
            name: 'None',
            altName: 'None',
            ico: 'fas fa-check has-text-danger'
          }
        }
      }
      this.selectedBatteryChargerStatus = this.definedTypes.batteryChargerTrackingOptions.find((x) => (x.name === this.currentInventory.batteryChargerStatus || x.altName === this.currentInventory.batteryChargerStatus))
      this.selectedBooksStatus = this.definedTypes.bookTrackingOptions.find((x) => (x.name === this.currentInventory.booksStatus || x.altName === this.currentInventory.booksStatus))
      this.selectedCarCoverStatus = this.definedTypes.carCoverTrackingOptions.find((x) => (x.name === this.currentInventory.carCoverStatus || x.altName === this.currentInventory.carCoverStatus))
      this.selectedAccessoryEventType = null
      this.selectedGivenToUser = null
      if (this.currentInventory) {
        this.$nextTick(() => {
          this.selectedAccessoryEventType = this.allowedAccessoryEventTypes[0]
        })
      }
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  watch: {
    '$route.params.id': function (id) {
      this.setDefaults()
    },
    selectedGivenToUser: function (newValue, oldValue) {
      if (newValue) {
        this.errors.clear('accessory-event-form')
      }
    },
    selectedBins: function (newValue, oldValue) {
      if (newValue) {
        this.errors.clear('accessory-event-form')
      }
    }
  },
  mounted: function () {
    this.setDefaults()
  }
}
</script>

<style scoped>
  .is-accessory-options .column {
    padding-bottom:0 !important;
    padding-top:0 !important;
  }

  .box {
      background-color: #f6f8fa;
    }
</style>
