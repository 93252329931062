<template>
    <section>
        <portal :to="headerPortal" :disabled="headerPortal === ''">
          <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Editing Deal
            <span v-if="isPreDeal" class="tag is-violet is-large is-size-6">{{getTitlePreDealText}}</span>
          </h1>
        </portal>

        <div v-if="bookedLocked && !this.bookedLockedNotificationHidden" class="notification is-info">
          <button class="delete" type="button" @click="bookedLockedNotificationHidden = true"></button>
          <span>
            <i class="fas fa-info-circle is-info"></i>
          </span>
            Please note: This deal is booked. The save button has been disabled.
        </div>
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <form @submit.prevent="validateBeforeSubmit" @change="onFormChange">
            <div id="deal-overview" class="box">
              <section-header title="Deal Overview" parentSelector="#deal-overview"></section-header>
              <div class="columns">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Deal #</label>
                    <div class="control is-width-100">
                      <input name="dealCode" data-vv-as="deal number" v-validate="'max:10'" :class="{'input': true, 'is-danger': errors.has('dealCode') }" type="text" placeholder="0000000000" v-model="currentDeal.dealCode">
                      <span v-show="errors.has('dealCode')" class="help is-danger">
                        <i v-show="errors.has('dealCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first('dealCode') }}
                      </span>
                    </div>
                  </div>

                  <div class="is-flex">
                    <button-list-selector validateAs="sale type" label="Sale Type" :required="true" v-model="selectedSaleType" :availableValues="this.definedTypes.dealSaleTypes" :allowDeselection="false"></button-list-selector>
                    <div v-if="showWholesaleTradeWarning" class="ml-10 notification is-danger">
                      <span>
                        <i class="fas fa-exclamation-triangle fa-2x"></i>
                      </span>
                      Wholesale deal cannot have trades or lease renewals
                    </div>
                  </div>

                  <button-list-selector validateAs="stock type" label="Stock Type" :required="true" v-model="selectedStockType" :availableValues="this.definedTypes.dealStockTypes" :activeValues="activeStockTypeItems" @click.native="onStockTypeClick" :allowDeselection="false"></button-list-selector>
                </div>
                <div class="column is-6">
                  <b-field label="Sold Date" v-if="!isPendingCredit && !isPreDeal">
                    <el-date-picker
                      @change="onFormChange()"
                      v-model="currentDeal.soldAt"
                      type="date"
                      placeholder="Click to select..."
                      :clearable="false"
                      :editable="false"
                      format="MM-dd-yyyy">
                    </el-date-picker>
                  </b-field>
                  <b-field label="Expected Delivery Date" v-if="allowedToSavePromisedDate">
                    <el-date-picker
                      @change="onFormChange()"
                      v-model="currentDeal.promiseDate"
                      type="datetime"
                      placeholder="Click to select..."
                      :clearable="false"
                      :editable="false"
                      format="MM-dd-yyyy h:mm A"
                      :default-value="this.getDefaultDateTimeForCalendar"
                      default-time="13:00:00">
                    </el-date-picker>
                  </b-field>
                  <b-field label="Appointment Date" v-if="showAppointmentDate">
                    <el-date-picker
                      :readonly="true"
                      @focus="openAppointmentModal()"
                      v-model="currentDeal.appointmentDate"
                      type="datetime"
                      class="is-width-200"
                      format="MM-dd-yyyy h:mm A"
                      :disabled="true">
                    </el-date-picker>
                  </b-field>
                  <div v-if="!showDeliveredDate">
                    <appointment-status-control v-model="currentDeal" ></appointment-status-control>
                  </div>
                  <b-field label="Delivered Date" v-if="showDeliveredDate">
                    <el-date-picker
                      @change="onFormChange()"
                      v-model="currentDeal.deliveryDate"
                      type="datetime"
                      placeholder="Click to select..."
                      :clearable="false"
                      :editable="false"
                      format="MM-dd-yyyy h:mm A"
                      :default-value="this.getDefaultDateTimeForCalendar"
                      default-time="13:00:00">
                    </el-date-picker>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column is-6">
                  <button-list-selector validateAs="deal status" :enabled="!isCancelledDeal" label="Deal Status" :required="true" v-model="selectedType" :availableValues="availableEditDealTypeItems" :activeValues="activeDealTypeItemsComputed"></button-list-selector>
                </div>
                <div class="column is-6" v-if="selectedType && selectedType.altName === 'AwaitingArrival'">
                  <div class="field">
                    <label class="label">Inbound arrival status</label>
                    <div class="control">
                      <multiselect v-model="arrivalStatus" :options="definedTypes.dealInboundStatusTypes.options" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select inbound arrival status" label="name" track-by="altName" data-vv-as="type" :class="errors.has('type') ? 'is-danger' : '' " name="type" :hideSelected="true" :disabled="isCancelledDeal"></multiselect>
                      <span v-show="showInboundSelectionRequired" class="help is-danger">
                        <i v-show="showInboundSelectionRequired" class="fas fa-exclamation-triangle"></i> Inbound status is required
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns is-multiline">
                <div class="column is-6">
                  <button-list-selector validateAs="contract type" label="Contract Type" :required="true" v-model="selectedContractType" :availableValues="this.definedTypes.dealContractTypes.options" :allowDeselection="false"></button-list-selector>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <div class="control">
                      <switch-selector type="is-info" label="Cash Down" v-model="isCashDown"></switch-selector>
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="isCashDown">
                  <div class="field">
                    <label class="label">Deposit Amount</label>
                    <div class="control has-icons-left">
                      <money name="depositAmount" data-vv-as="deposit amount" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('depositAmount') }" type="text" placeholder="0000.00" v-model="currentDeal.depositAmount" v-bind="$globalMoneyFormat"></money>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <span v-show="errors.has('depositAmount')" class="help is-danger">
                      <i v-show="errors.has('depositAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('depositAmount') }}
                    </span>
                  </div>
                </div>
                <div class="column is-6" v-if="isLeaseContractType">
                  <div class="field" >
                    <label class="label">Lease Contract Term</label>
                    <div class="control">
                      <multiselect @select="onFormChange()" :hideSelected="true" data-vv-as="lease contract term" :multiple="false" name="leaseContractTerm" :class="{'is-danger': errors.has('leaseContractTerm') }" v-model="selectedLeaseContractTermType" label="name" :options="orderedDealLeaseContractTermTypes" placeholder="Lease Contract Term" track-by="name"></multiselect>
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="isLeaseContractType">
                  <div class="field">
                    <label class="label">Lease Contract Amount
                    </label>
                    <div class="control has-icons-left">
                      <money name="leaseContractAmount" data-vv-as="lease contract amount" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('leaseContractAmount') }" type="text" placeholder="0000.00" v-model="currentDeal.leaseAmount" v-bind="$globalMoneyFormat"></money>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <span v-show="errors.has('leaseContractAmount')" class="help is-danger">
                      <i v-show="errors.has('leaseContractAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('leaseContractAmount') }}
                    </span>
                  </div>
                </div>
                <div class="column is-6" v-if="isLeaseContractType">
                  <div class="field">
                    <label class="label">Residual Value</label>
                    <div class="control has-icons-left">
                      <money name="residualValue" data-vv-as="residual value" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('residualValue') }" type="text" placeholder="0000.00" v-model="currentDeal.residualValue" v-bind="$globalMoneyFormat"></money>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <span v-show="errors.has('residualValue')" class="help is-danger">
                      <i v-show="errors.has('residualValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('residualValue') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-6">
                  <supplier-selector label="Wholesaled to" validateAs="Wholesaled to" placeholder="Type to search for a loaded wholesaler" v-if="isSaleTypeOfWholesale" v-model="selectedSupplier" :required="true"></supplier-selector>
                  <customer-selector v-if="isSaleTypeOfRetail" v-model="selectedCustomer" :selectedDealer="this.selectedDealer" :required="true"></customer-selector>
                  <user-selector  v-model="selectedSalesAgent" v-validate="isSalesAgentRequired ? 'required' : ''" filter="Sales" label="Sales Agent" validateAs="sales agent" :selectedDealer="this.selectedDealer" :required="isSalesAgentRequired"></user-selector>
                  <user-selector
                    v-model="selectedSalesAgentManager"
                    v-validate="''"
                    label="Sales Agent Manager"
                    validateAs="sales agent manager"
                    :selectedDealer="selectedDealer"
                    :required="true">
                  </user-selector>
                  <label class="label-frontgp is-pulled-right" :class="{'has-text-danger': (selectedSalesAgentManager === null) || (selectedSalesAgentManager && selectedSalesAgentManager.fullName !== currentUser.fullName), 'has-text-success': selectedSalesAgentManager && selectedSalesAgentManager.fullName === currentUser.fullName}" @click="setCurrentAsSalesManager" title="Click to make the current user the sales agent manager.">{{currentUser.fullName}}</label>
                  <dealer-selector v-model="selectedDealer" :required="true" :useValidator="this.$validator" v-show="false"></dealer-selector>
                </div>
                <div class="column is-6">
                  <customer-selector v-if="isSaleTypeOfRetail" v-model="selectedCoCustomer" :selectedDealer="this.selectedDealer" :required="false" label="Co-Customer"></customer-selector>
                  <button-list-selector v-if="isSaleTypeOfRetail && selectedCoCustomer" validateAs="relationship status" label="Co-Customer Relationship Status" :required="selectedCoCustomer !== null" v-model="selectedCoCustomerRelationship" :availableValues="this.definedTypes.relationshipTypes.options" :allowDeselection="false"></button-list-selector>
                  <user-selector v-model="selectedFinanceProcessedBy" filter="F&I Manager" label="Finance Processed By" validateAs="finance processed by" :selectedDealer="this.selectedDealer" :required="isFinanceProcessedByRequired"></user-selector>
                  <lead-source-selector v-model="selectedLeadSource" :required="isLeadSourceRequired" label="Lead Source" validateAs="lead source" :selectedDealer="this.selectedDealer"></lead-source-selector>
                </div>
              </div>
              <div>
                <switch-selector type="is-info" label="Any Supporting Documentation?" v-model="hasDealAttachments"></switch-selector>
                <div id="document-section" class="box" v-show="hasDealAttachments">
                  <section-header title="Documents" parentSelector="#document-section"></section-header>
                  <div class="columns is-multiline">
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="saleDocuments"
                          label="Sale Documents"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6" v-if="false">
                      <div class="field">
                        <file-selector
                          v-model="creditCardDocuments"
                          label="Credit Card"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="buyersAgreementDocuments"
                          label="Buyers Agreement"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="invoiceDocuments"
                          label="Invoice"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="otherDocuments"
                          label="Other"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6" v-if="false">
                      <div class="field">
                        <file-selector
                          v-model="wireDocuments"
                          label="Wire"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="generalDocuments"
                          label="General"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="fundingLetterDocuments"
                          label="Funding Letter"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6" v-if="false">
                      <div class="field">
                        <file-selector
                          v-model="squareDocuments"
                          label="Square"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6" v-if="false">
                      <div class="field">
                        <file-selector
                          v-model="cashDocuments"
                          label="Cash"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6" v-if="false">
                      <div class="field">
                        <file-selector
                          v-model="checkDocuments"
                          label="Check"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="dealJacketDocuments"
                          label="Deal Jacket"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="customerVerificationDocuments"
                          label="Customer Verification"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="cdkRecapDocuments"
                          label="CDK Recap"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="dealPresentationDocuments"
                          label="Deal Presentation"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="bankCreditApplicationApprovalDocuments"
                          label="Bank Credit Application Approval"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="vehicleCostDocuments"
                          label="Vehicle Cost"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <file-selector
                          v-model="cdkWashoutDocuments"
                          label="CDK Washout"
                          :enabled="true"
                          :multiple="true"
                          :showFileOnly="false"
                          :isBoxed="false"
                          :formatImages="true"
                          :usePortalViewer="true"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="inventory-sold-section" class="box">
              <section-header title="Inventory Sold" parentSelector="#inventory-sold-section"></section-header>
              <sold-inventory-capture
                v-for="(soldItem, index) in soldDealItems"
                :key="100 + soldItem.config.sectionId"
                :vehicleTypes="vehicleTypes"
                :selectedDealer="selectedDealer"
                v-model="soldDealItems[index]"
                :selectedStockType.sync="selectedStockType"
                :selectedDealType.sync="selectedType"
                :selectedSaleType.sync="selectedSaleType"
                :tradeAcvDifference="totalAcvDifference"
                :additionalCostsGP="additionalCostsGP"
                :additionalCostsRecon="additionalCostsRecon">
              </sold-inventory-capture>
              <div class="inner-add-remove-button" v-show="false" v-if="showSoldItemPlusBtn">
                <a class="button is-primary" @click="AddDealItem({type: 'Sold'})">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <a class="button is-danger" v-if="this.soldDealItems.length > 1" @click="removeDealItem('Sold')">
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
              </div>
            </div>

            <div id="supplement-cost-section" class="box" v-show="showCostsSection">
              <section-header title="Additional Costs" parentSelector="#supplement-cost-section"></section-header>
              <div class="columns">
                <div class="column is-12">
                  <b-table :data="this.supplementalCosts" id="supplemental-costs" name="supplemental-costs">
                    <template slot-scope="props">
                      <b-table-column field="name" label="Name" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.name }}
                      </b-table-column>

                      <b-table-column field="vendorName" label="Vendor" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.vendorName }}
                      </b-table-column>

                      <b-table-column field="processingAction" label="Nett Action" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.processingAction }}
                      </b-table-column>

                      <b-table-column field="isInGlBalance" label="Is In GL Balance" :class="{'striked': props.row.delete === true}" sortable>
                        {{ humanizedSwitch(props.row.isInGlBalance) }}
                      </b-table-column>

                      <b-table-column field="description" label="Description" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.description }}
                      </b-table-column>

                      <b-table-column field="cost" label="Cost" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.cost | currency }}
                      </b-table-column>

                      <b-table-column field="sellingPrice" label="Selling Price" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.sellingPrice | currency }}
                      </b-table-column>

                      <b-table-column field="" label="Actions">
                        <span class="icon" @click="editSupplementalCost(props.row, props.index)" v-if="props.row.delete !== true">
                          <i class="fal fa-pencil"></i>
                        </span>
                        <span class="icon" @click="removeSupplementalCost(props.index)" v-if="props.row.delete !== true">
                          <i class="fal fa-minus-circle"></i>
                        </span>
                        <span class="is-size-7 has-text-danger" v-if="props.row.delete === true">
                          Pending Delete
                        </span>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No additional costs have been added.</p>
                        </div>
                      </section>
                    </template>
                    <template slot="footer" v-if="availableAdditionalCosts.length > 0">
                      <th colspan="6"></th>
                      <th colspan="1"><label class="label">{{additionalCostsTotal | currency}}</label></th>
                      <th colspan="1"></th>
                    </template>
                  </b-table>
                </div>
              </div>
              <supplement-cost-capture :value="this.newSupplementalCost" :supplementalCosts="this.supplementalCosts"></supplement-cost-capture>
              <div class="inner-add-remove-button">
                <a class="button is-primary" @click="addSupplementalCost()">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <button class="button is-warning is-pulled-right" type="button" @click="restoreDealAdditionalCosts()">Restore Defaults</button>
              </div>
            </div>

            <div id="commission-section" class="box" v-show="showCommissionsSection">
              <section-header title="Commissions" parentSelector="#commission-section"></section-header>
              <div class="columns">
                <div class="column is-12">
                  <b-table :data="this.commissions" id="commissions" name="commissions">
                    <template slot-scope="props">
                      <b-table-column field="salesPersonName" label="Sales Person" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.salesPersonName }}
                      </b-table-column>

                      <b-table-column field="commission" label="Commission" :class="{'striked': props.row.delete === true}" sortable>
                        {{ props.row.commission | currency }}
                      </b-table-column>

                      <b-table-column field="" label="Actions">
                        <section style="padding-top: 0em; display: flex; justify-content: flex-start;flex-wrap: wrap;">
                          <span class="icon" @click="editCommission(props.row, props.index)" v-if="props.row.delete !== true">
                            <i class="fal fa-pencil"></i>
                          </span>
                          <span class="icon" @click="removeCommission(props.index)" v-if="props.row.delete !== true">
                            <i class="fal fa-minus-circle"></i>
                          </span>
                          <span class="is-size-7 has-text-danger" v-if="props.row.delete === true">
                            Pending Delete
                          </span>
                          <deal-commission-processing-action :commissionRequest="props.row" :saveStatusChange="false" v-on:commission-request-status-change="commissionRequestStatusChange" :index="props.index"></deal-commission-processing-action>
                        </section>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No commissions have been added.</p>
                        </div>
                      </section>
                    </template>
                    <template slot="footer" v-if="availableCommissions.length > 0">
                      <th colspan="1"></th>
                      <th colspan="1"><label class="label">{{commissionsTotal | currency}}</label></th>
                      <th colspan="1"></th>
                    </template>
                  </b-table>
                </div>
              </div>
              <commission-capture :value="this.newCommission" :commissions="this.commissions"></commission-capture>
              <div class="inner-add-remove-button">
                <a class="button is-primary" @click="addCommission()">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
              </div>
            </div>

            <div v-if="showWholesaleTradeWarning" class="ml-10 notification is-danger">
              <span>
                <i class="fas fa-exclamation-triangle fa-2x"></i>
              </span>
              Wholesale deal cannot have trades or lease renewals
            </div>

            <div id="trade-section" class="box" v-if="showTradeSection">
              <section-header title="Trades" parentSelector="#trade-section"></section-header>
              <make-selector v-if="!hasTradeInventory && !hasLeaseInventory" label="Previous Vehicle Make" :required="false" v-model="selectedMake" :selectedDealer="this.selectedDealer"></make-selector>
              <div class="field">
                <label class="label">Do you have a trade?</label>
                <div class="control">
                  <b-switch type="is-info" v-model="hasTradeInventory" @click.native="toggleTrade" :disabled="hasLeaseInventory">{{ humanizedHasTradeInventory }}</b-switch>
                </div>
              </div>
              <div v-if="hasTradeInventory">
                <lease-renewal-capture
                  v-for="(tradeItem, index) in tradeDealItems"
                  :key="200 + tradeItem.config.sectionId"
                  :showPrice="true"
                  :showStockNoLabel="true"
                  :selectedDealer="selectedDealer"
                  v-model="tradeDealItems[index]"
                  :soldStockNo="soldDealItems.length > 0 ? soldDealItems[0].forInventory.stockNo : ''"
                  :defaultSalesPerson="selectedSalesAgent"
                  :defaultSellerType="selectedSellerType"
                  :defaultSeller="selectedSeller">
                </lease-renewal-capture>
              </div>
              <div class="inner-add-remove-button" v-if="hasTradeInventory">
                <a class="button is-primary" @click="AddDealItem({type: 'Trade'})">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <a class="button is-danger" @click="removeDealItem('Trade')" v-if="tradeDealItems.length > 1">
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
              </div>
            </div>

            <div id="lease-renewal-section" class="box" v-if="showLeaseRenewalSection">
              <section-header title="Lease Renewal / Lease Return" parentSelector="#lease-renewal-section"></section-header>
              <div class="field">
                <label class="label">Is there a lease to return?</label>
                <div class="control">
                  <b-switch type="is-info" v-model="hasLeaseInventory" @click.native="toggleLeaseRenewal" :disabled="hasTradeInventory">{{ humanizedHasLeaseRenewal }}</b-switch>
                </div>
              </div>
              <div v-if="hasLeaseInventory">
                <lease-renewal-capture
                  v-for="(leaseItem, index) in leaseRenewalDealItems"
                  :key="300 + leaseItem.config.sectionId"
                  :selectedDealer="selectedDealer"
                  v-model="leaseRenewalDealItems[index]"
                  mode="LeaseRenewal"
                  :defaultSalesPerson="selectedSalesAgent"
                  :defaultSellerType="selectedSellerType"
                  :defaultSeller="selectedSeller">
                </lease-renewal-capture>
              </div>
              <div class="inner-add-remove-button" v-if="hasLeaseInventory">
                <a class="button is-primary" @click="AddDealItem({type: 'LeaseRenewal'})">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <a class="button is-danger" @click="removeDealItem('LeaseRenewal')" v-if="leaseRenewalDealItems.length > 1">
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
              </div>
            </div>

            <div id="note-section" class="box" v-show="showNotesSection">
              <section-header title="Notes & Comments" parentSelector="#note-section"></section-header>
              <component v-for="noteSection in noteSectionArray" :key="noteSection.id" :is="noteSection.name" v-model="noteValueArray" label="Note" :required="false" :showDealNoteTypeSelector=true></component>
              <div class="inner-add-remove-button">
                <a class="button is-primary" @click="AddNoteValueSection">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <a class="button is-danger" v-if="this.noteSectionArray.length > 1" @click="RemoveNoteValueSection">
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
              </div>
              <note-timeline-view v-model="currentDeal.notes" entity="deal"></note-timeline-view>
            </div>

            <div id="tags-section" class="box">
              <section-header title="Tags" parentSelector="#tags-section"></section-header>
              <tag-selector v-model="selectedTags" :required="false"></tag-selector>
            </div>

            <portal :to="actionPortal">
              <div class="field is-grouped">
                <p class="control" v-if="hasFeatureAccess('deal.modify') && !isAllocated" >
                  <button class="button is-primary" :class="{'is-loading': (isSaving || isLoading) }" :disabled="bookedLocked || isUploadingFile || childComponentIsUploadingFile || cancelLocked"  type="button" @click="validateBeforeSubmit">Save</button>
                </p>
                <p class="control">
                  <a class="button is-danger" :class="{'is-loading': (isSaving || isLoading) }" @click="onCancel()">Cancel</a>
                </p>
                <p class="control" v-if="bookedLocked && hasFeatureAccess('deal.modify.unlockbooked')" >
                  <a class="button is-link is-small" @click="bookedLocked = false">
                      <span class="icon is-small">
                      <i class="fas fa-unlock"></i>
                      </span>
                  </a>
                </p>
              </div>
            </portal>

            <portal to="footer-toolbar-middle" :disabled="!canShowFooterToolbarMiddleRight">
              <div class="footer-toolbar-content subtitle is-7 is-hidden-mobile">
                {{dealOverview}}
              </div>
            </portal>

            <portal to="footer-toolbar-right" :disabled="!canShowFooterToolbarMiddleRight">
              <div class="quick-action-list">
                <deal-buyers-agreement ref="buyersAgreementAction" :currentDeal="modifiedDeal" v-if="modifiedDeal && modifiedDeal.id > 0"></deal-buyers-agreement>
              </div>
            </portal>
        </form>
        <b-modal :active.sync="isVehicleAlreadyLocatedDialogActive" :width="640" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Confirmation</p>
            </header>
            <section class="modal-card-body">
              Have you already located the required vehicle for this deal?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onVehicleAlreadyLocated()" type="button">Yes</button>
              <button class="button is-danger" @click="onVehicleNotAlreadyLocated()" type="button">No</button>
            </footer>
          </div>
        </b-modal>

        <b-modal :active.sync="dealBookedActive" :width="640" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Warning</p>
            </header>
            <section class="modal-card-body">
              Do you want to book this deal?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onConfirmSaveBookedDeal(true)" type="button">Yes</button>
              <button class="button is-danger" @click="onConfirmSaveBookedDeal(false)" type="button">No</button>
            </footer>
          </div>
        </b-modal>

        <b-modal :active.sync="cancelBackupDealsActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Do you want to cancel any backup deals?</p>
            </header>
            <section class="modal-card-body">
              <cancel-backup-deals-control :selectedDealIds.sync="selectedDealIds" :backupDeals="backupDeals" :hasErrors.sync="cancelBackupDealsErrors"/>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onConfirmCancelBackupDeals(true)" type="button">Yes</button>
              <button class="button is-danger" @click="onConfirmCancelBackupDeals(false)" type="button">No</button>
            </footer>
          </div>
        </b-modal>

        <b-modal :active.sync="selectBackupDealActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Please select a primary deal from the list of backups below:</p>
            </header>
            <section class="modal-card-body">
              <select-primary-backup-deal-control :selectedDealItemId.sync="selectedDealItemId" :backupDeals="backupDeals" :hasErrors.sync="selectBackupDealErrors"/>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onConfirmSelectBackupDeal(true)" type="button">Confirm</button>
              <!--<button class="button is-danger" @click="onConfirmSelectBackupDeal(false)" type="button">Cancel</button>-->
            </footer>
          </div>
        </b-modal>

        <b-modal :active.sync="isBuyersAgreementOptionActive" :width="640" scroll="keep" :has-modal-card="true" v-if="modifiedDeal">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Issue Bill of Sale</p>
            </header>
            <section class="modal-card-body">
              Do you want to send the Bill of Sale to sell stock #{{firstSoldItemStockNo}} from {{selectedBuyerName}}?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onConfirmBuyersAgreement()">Yes</button>
              <button class="button is-danger" @click="onCancelBuyersAgreement">No</button>
            </footer>
          </div>
        </b-modal>

        <b-modal :active.sync="isLeaseAgreementOptionActive" :width="640" scroll="keep" :has-modal-card="true" v-if="modifiedDeal">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Issue Lease Agreement</p>
            </header>
            <section class="modal-card-body">
              Do you want to send a Lease Agreement for stock #{{firstSoldItemStockNo}} from {{selectedBuyerName}}?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onConfirmLeaseAgreement()">Yes</button>
              <button class="button is-danger" @click="onCancelLeaseAgreement">No</button>
            </footer>
          </div>
        </b-modal>

        <b-modal :active.sync="isCreditApplicationTypeChangeDialogActive" :width="640" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Confirmation</p>
            </header>
            <section class="modal-card-body">
              There is a business credit application available for a selected customer, would you like to resend as a co-application?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="emailCreditApplication()" type="button">Yes</button>
              <button class="button is-danger" @click="cancelEmailCreditApplication()" type="button">No</button>
            </footer>
          </div>
        </b-modal>

        <deal-get-ready-capture
          :dealItems="dealItems"
          :openDialog="isGetReadyCapture"
          :expectedDeliveryDate="currentDeal.promiseDate"
          :usePortal="usePortal"
          v-on:get-ready-created="onSaveGetReady"
          v-on:get-ready-cancelled="cancelGetReady"
          v-on:get-ready-skipped="skipGetReady">
        </deal-get-ready-capture>

        <credit-application-reassignment
          v-if="currentDeal && selectedType"
          ref="creditApplicationReassignmentCheckControl"
          :currentDeal="currentDeal"
          :isDealTypePendingCredit="isDealTypePendingCredit"
          :isCreditAppReassignmentActive.sync="isCreditAppReassignmentActive"
          v-on:credit-app-reassignment-close="closeCreditAppAssignmentModal"
          v-on:reassign-credit-application="reassignCreditApplication">
        </credit-application-reassignment>

      <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
    </section>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import dealService from '@/services/dealService'
import customerService from '@/services/customerService'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import dealMixin from '@/mixins/deal/index'
import supplierService from '@/services/supplierService'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import utilitiesMixin from '@/mixins/generic/utilities'
import SwitchSelector from '@/components/generic/SwitchSelector'
import AppointmentStatusControl from '@/components/generic/sales/AppointmentStatusControl'
import DealGetReadyCapture from '@/components/sales/deals/DealGetReadyCapture'
import DealCommissionProcessingAction from '@/components/sales/deals/DealCommissionProcessingAction'
import CreditApplicationReassignmentAction from '@/components/sales/deals/CreditApplicationReassignmentAction'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'EditDeal',
  mixins: [validateBeforeSubmitMixin, dealMixin, humanizedSwitchMixin, dealInventoryStyleClasses, utilitiesMixin],
  components: {
    'switch-selector': SwitchSelector,
    'appointment-status-control': AppointmentStatusControl,
    'deal-get-ready-capture': DealGetReadyCapture,
    'deal-commission-processing-action': DealCommissionProcessingAction,
    'credit-application-reassignment': CreditApplicationReassignmentAction
  },
  computed: {
    ...mapUserGetters(['allUsers', 'currentUser']),
    isAllocated: function () {
      if (this.currentDeal && this.currentDeal.type === 'Allocated') {
        return true
      }
      return false
    },
    orderedDealLeaseContractTermTypes: function () {
      let dealLeaseContractTermTypes = this.definedTypes.dealLeaseContractTermTypes.options
      return _.sortBy(dealLeaseContractTermTypes, ['name'])
    },
    resendCreditApplicationRequired: function () {
      if (this.selectedCustomer && this.selectedCoCustomer) {
        let creditApplicationDealId = this.selectedCustomer.creditApplicationDealId
        if (creditApplicationDealId === this.currentDeal.id) {
          if (this.currentDeal.creditApplicationStatus !== 'Completed' && this.currentDeal.creditApplicationType === 'Business') {
            return true
          }
        }
      }
      return false
    },
    isCreditApplicationFinalized: function () {
      if (this.currentDeal === null) return false
      let creditAppStatus = this.currentDeal.creditApplicationStatus
      return creditAppStatus === 'Sent' || creditAppStatus === 'Pending' || creditAppStatus === 'Completed'
    },
    isDealTypePendingCredit: function () {
      return this.selectedType && this.selectedType.altName === 'PendingCredit'
    }
  },
  methods: {
    onSaveGetReady: function (soldServiceRequest) {
      this.soldServiceRequest = soldServiceRequest
      this.soldServiceRequest.lines = soldServiceRequest.lines.map((x) => {
        return {
          description: x.description,
          isActive: x.isActive,
          opCode: x.opCode,
          position: x.position
        }
      })

      if (soldServiceRequest.updateExpectedDeliveryDate) {
        this.currentDeal.promiseDate = soldServiceRequest.requiredAt
      }

      this.hasServiceRequest = true
      this.confirmedGetReadySave = true
      this.isGetReadyCapture = false
      this.onSave()
    },
    onSave: function () {
      if ((this.selectedType.name === 'Pending Delivery' && this.mustCreateDealPendingServiceRequest) && (!this.hasServiceRequest || !this.confirmedGetReadySave)) {
        this.isGetReadyCapture = true
        return
      }

      if (this.selectedType.name === 'Booked' && !this.confirmedBookedSave) {
        this.dealBookedActive = true
        return
      }

      if (this.isSaleTypeOfRetail && this.selectedType.name === 'Booked' && !(this.hasTradeInventory || this.hasLeaseInventory || this.selectedMake)) {
        this.serverErrorMessage = 'One of previous make or trade or lease renewal / lease return is required for retail a deal.'
        return
      }
      var dealModel = {
        ...this.currentDeal,
        isCreate: false,
        dealerId: this.$store.state.dealer.currentDealer.id,
        type: this.selectedType.altName,
        stockType: this.selectedStockType.altName,
        saleType: this.selectedSaleType.name,
        salesPersonId: (this.selectedSalesAgent != null) ? this.selectedSalesAgent.id : null,
        financeProcessedById: (this.selectedFinanceProcessedBy != null) ? this.selectedFinanceProcessedBy.id : null,
        sourceId: (this.selectedLeadSource !== null) ? this.selectedLeadSource.id : null,
        status: 'Active',
        items: [],
        notes: [],
        supplementalCosts: [],
        commissions: [],
        tags: [],
        previousVehicleMakeId: (this.selectedMake !== null && this.selectedMake !== undefined) ? this.selectedMake.id : null,
        supplierId: (this.selectedSupplier != null && this.selectedSaleType !== null && this.selectedSaleType.name === 'Wholesale') ? this.selectedSupplier.id : null,
        coCustomerRelationship: !this.selectedCoCustomer ? null : this.selectedCoCustomerRelationship.altName,
        salesPersonManagerId: (this.selectedSalesAgentManager != null) ? this.selectedSalesAgentManager.id : null,
        soldServiceRequest: this.soldServiceRequest,
        contractType: this.selectedContractType.name,
        leaseContractTermType: this.selectedContractType.name === 'Retail' || !this.selectedLeaseContractTermType ? null : this.selectedLeaseContractTermType.altName,
        leaseAmount: this.selectedContractType.name === 'Retail' ? null : this.currentDeal.leaseAmount,
        residualValue: this.selectedContractType.name === 'Retail' ? null : this.currentDeal.residualValue,
        inboundStatusType: this.arrivalStatus ? this.arrivalStatus.altName : null,
        cashDown: this.isCashDown,
        depositAmount: !this.isCashDown ? null : this.currentDeal.depositAmount,
        resendCreditApplication: this.currentDeal.resendCreditApplication
      }

      // Reset customers objects here as they are potentially huge from the initial load and breaks POST json limit in MVC
      dealModel.customer = {
        id: (this.selectedCustomer != null && this.selectedSaleType !== null && this.selectedSaleType.name === 'Retail') ? this.selectedCustomer.id : null
      }

      // Reset customers objects here as they are potentially huge from the initial load and breaks POST json limit in MVC
      dealModel.coCustomer = {
        id: (this.selectedCoCustomer != null && this.selectedSaleType !== null && this.selectedSaleType.name === 'Retail') ? this.selectedCoCustomer.id : null
      }

      if (dealModel.soldAt) {
        dealModel.soldAt = this.convertLocalToUtc(new Date(dealModel.soldAt))
      }
      if (dealModel.promiseDate && this.allowedToSavePromisedDate) {
        dealModel.promiseDate = this.convertLocalToUtc(new Date(dealModel.promiseDate))
      }
      if (dealModel.deliveryDate && this.showDeliveredDate) {
        dealModel.deliveryDate = this.convertLocalToUtc(new Date(dealModel.deliveryDate))
      }

      if (this.saleDocuments.length > 0 && this.hasDealAttachments) {
        let saleDocumentFiles = this.saleDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'SaleDocument',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...saleDocumentFiles)
      }
      if (this.creditCardDocuments.length > 0 && this.hasDealAttachments) {
        let creditCardDocumentFiles = this.creditCardDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'CreditCard',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...creditCardDocumentFiles)
      }
      if (this.buyersAgreementDocuments.length > 0 && this.hasDealAttachments) {
        let buyersAgreementDocumentFiles = this.buyersAgreementDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'BuyersAgreement',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...buyersAgreementDocumentFiles)
      }
      if (this.invoiceDocuments.length > 0 && this.hasDealAttachments) {
        let invoiceDocumentFiles = this.invoiceDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Invoice',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...invoiceDocumentFiles)
      }
      if (this.otherDocuments.length > 0 && this.hasDealAttachments) {
        let otherDocumentFiles = this.otherDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Other',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...otherDocumentFiles)
      }
      if (this.wireDocuments.length > 0 && this.hasDealAttachments) {
        let wireDocumentFiles = this.wireDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Wire',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...wireDocumentFiles)
      }
      if (this.generalDocuments.length > 0 && this.hasDealAttachments) {
        let generalDocumentFiles = this.generalDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'General',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...generalDocumentFiles)
      }
      if (this.fundingLetterDocuments.length > 0 && this.hasDealAttachments) {
        let fundingLetterDocumentFiles = this.fundingLetterDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'FundingLetter',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...fundingLetterDocumentFiles)
      }
      if (this.squareDocuments.length > 0 && this.hasDealAttachments) {
        let squareDocumentFiles = this.squareDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Square',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...squareDocumentFiles)
      }
      if (this.cashDocuments.length > 0 && this.hasDealAttachments) {
        let cashDocumentFiles = this.cashDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Cash',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...cashDocumentFiles)
      }
      if (this.checkDocuments.length > 0 && this.hasDealAttachments) {
        let checkDocumentFiles = this.checkDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Check',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...checkDocumentFiles)
      }
      if (this.dealJacketDocuments.length > 0 && this.hasDealAttachments) {
        let dealJacketDocumentFiles = this.dealJacketDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'DealJacket',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...dealJacketDocumentFiles)
      }
      if (this.customerVerificationDocuments.length > 0 && this.hasDealAttachments) {
        let customerVerificationDocumentFiles = this.customerVerificationDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'CustomerVerification',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...customerVerificationDocumentFiles)
      }
      if (this.cdkRecapDocuments.length > 0 && this.hasDealAttachments) {
        let cdkRecapDocumentFiles = this.cdkRecapDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'CdkRecap',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...cdkRecapDocumentFiles)
      }
      if (this.dealPresentationDocuments.length > 0 && this.hasDealAttachments) {
        let dealPresentationDocumentFiles = this.dealPresentationDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'DealPresentation',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...dealPresentationDocumentFiles)
      }
      if (this.bankCreditApplicationApprovalDocuments.length > 0 && this.hasDealAttachments) {
        let bankCreditApplicationApprovalDocumentFiles = this.bankCreditApplicationApprovalDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'BankCreditApplicationApproval',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...bankCreditApplicationApprovalDocumentFiles)
      }
      if (this.vehicleCostDocuments.length > 0 && this.hasDealAttachments) {
        let vehicleCostDocumentFiles = this.vehicleCostDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'VehicleCost',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...vehicleCostDocumentFiles)
      }
      if (this.cdkWashoutDocuments.length > 0 && this.hasDealAttachments) {
        let cdkWashoutDocumentFiles = this.cdkWashoutDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'CdkWashout',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...cdkWashoutDocumentFiles)
      }

      // inventory sold
      this.saveDealItem(dealModel, 'Sold')

      // inventory trade
      if (this.hasTradeInventory) {
        this.saveDealItem(dealModel, 'Trade')
      }

      // inventory lease
      if (this.hasLeaseInventory) {
        this.saveDealItem(dealModel, 'LeaseRenewal')
      }

      // notes
      this.addDealNote(dealModel)

      this.saveSupplementalCost(dealModel)

      this.saveCommission(dealModel)

      if (this.selectedTags.length > 0) {
        dealModel.tags = this.selectedTags
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      dealService.update(dealModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.modifiedDeal = response.deal
          this.backupDeals = response.backupDeals || []
          this.onChangesSaved()
          if (this.dealCancellationRequired) {
            if (this.buyersAgreementRequired) {
              this.isBuyersAgreementOptionActive = true
            } else if (this.leaseAgreementRequired) {
              this.isLeaseAgreementOptionActive = true
            } else {
              this.cancelBackupDealsActive = true
            }
          } else {
            if (this.dealPromotionRequired) {
              if (this.buyersAgreementRequired) {
                this.isBuyersAgreementOptionActive = true
              } else if (this.leaseAgreementRequired) {
                this.isLeaseAgreementOptionActive = true
              } else {
                this.selectBackupDealActive = true
              }
            } else {
              if (this.buyersAgreementRequired) {
                this.isBuyersAgreementOptionActive = true
              } else if (this.leaseAgreementRequired) {
                this.isLeaseAgreementOptionActive = true
              } else if (this.resendCreditApplicationRequired) {
                this.isCreditApplicationTypeChangeDialogActive = true
              } else {
                this.onComplete(this.modifiedDeal)
              }
            }
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    loadDeal (id) {
      this.currentDealId = id
      this.isLoading = true
      dealService.single(this.currentDealId).then(response => {
        this.currentDeal = response.deal
        this.initInputValues()
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    initInputValues: function () {
      if (this.currentDeal != null) {
        this.selectedSaleType = this.definedTypes.dealSaleTypes.find((x) => x.name === this.currentDeal.saleType)

        this.$nextTick(() => {
          this.selectedContractType = this.definedTypes.dealContractTypes.options.find((x) => x.name === this.currentDeal.contractType)
          this.selectedLeaseContractTermType = this.currentDeal.leaseContractTermType !== '' ? this.definedTypes.dealLeaseContractTermTypes.options.find((x) => x.altName === this.currentDeal.leaseContractTermType) : null
        })

        this.setVehicleTypes(this.currentDeal.saleType.name)
        this.hasServiceRequest = this.currentDeal.hasServiceRequest
        if (this.hasServiceRequest) {
          this.confirmedGetReadySave = true
        }

        this.selectedStockType = (!this.$route.params.stockType)
          ? this.definedTypes.dealStockTypes.find((x) => x.name === this.currentDeal.stockType || x.altName === this.currentDeal.stockType)
          : this.$route.params.stockType

        this.selectedType = (!this.$route.params.dealStatus)
          ? this.definedTypes.dealTypes.find((x) => x.name === this.currentDeal.type || x.altName === this.currentDeal.type)
          : this.$route.params.dealStatus
        this.selectedDealer = this.getDealer(this.currentDeal.dealerId)
        this.selectedCustomer = (this.currentDeal.customer && this.currentDeal.customer.id > 0) ? this.currentDeal.customer : null
        this.selectedCoCustomer = (this.currentDeal.coCustomer && this.currentDeal.coCustomer.id > 0) ? this.currentDeal.coCustomer : null

        if (this.selectedCoCustomer) {
          this.selectedCoCustomerRelationship = this.definedTypes.relationshipTypes.options.find((x) => x.altName === this.currentDeal.coCustomerRelationship)
        } else {
          this.selectedCoCustomerRelationship = null
        }

        this.isCashDown = this.currentDeal.cashDown

        if (this.currentDeal.supplierId !== null) {
          supplierService.single(this.currentDeal.supplierId).then(response => {
            var supplier = response.supplier
            this.selectedSupplier = supplier
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isLoading = false
          })
        }

        this.selectedSalesAgent = (this.currentDeal.salesPersonId) ? { id: this.currentDeal.salesPersonId, fullName: this.currentDeal.salesPersonName, dealerId: this.currentDeal.dealerId } : null
        this.selectedFinanceProcessedBy = (this.currentDeal.financeProcessedById) ? { id: this.currentDeal.financeProcessedById, fullName: this.currentDeal.financeProcessedByName, dealerId: this.currentDeal.dealerId } : null
        this.selectedLeadSource = (this.currentDeal.sourceId) ? { id: this.currentDeal.sourceId, name: this.currentDeal.sourceName, dealerId: this.currentDeal.dealerId } : null
        this.selectedMake = (this.currentDeal.previousVehicleMakeId) ? { id: this.currentDeal.previousVehicleMakeId, name: this.currentDeal.previousVehicleMakeName } : null
        this.selectedSalesAgentManager = (this.currentDeal.salesPersonManagerId) ? { id: this.currentDeal.salesPersonManagerId, fullName: this.currentDeal.salesManagerName, dealerId: this.currentDeal.dealerId } : null

        // add Sold items
        this.currentDeal.items.filter(x => x.dealItemType === 'Sold').forEach(function (dealItem) {
          var config = {
            sectionId: this.soldDealItems.length + this.getIndexOffset('Sold'),
            loadInventoryOnly: false
          }
          dealItem.config = config
          this.dealItems.push(dealItem)
        }, this)

        // add Trade items
        this.currentDeal.items.filter(x => x.dealItemType === 'Trade').forEach(function (dealItem) {
          this.hasTradeInventory = true
          var config = {
            sectionId: this.tradeDealItems.length + this.getIndexOffset('Trade'),
            loadInventoryOnly: false
          }
          dealItem.config = config
          this.dealItems.push(dealItem)
        }, this)
        // check if we passing tradeItem through prop to be added to trade list, do not add if already in list
        if (this.tradeInventoryItem) {
          let foundTrade = this.currentDeal.items.find(x => x.dealItemType === 'Trade' && x.forInventory.id === this.tradeInventoryItem.id)
          if (!foundTrade) {
            this.hasTradeInventory = true
            this.AddDealItem({ loadInventoryOnly: true, forInventory: this.tradeInventoryItem, type: 'Trade' })
          }
        }

        // add LeaseRenewal items
        this.currentDeal.items.filter(x => x.dealItemType === 'LeaseRenewal').forEach(function (dealItem) {
          this.hasLeaseInventory = true
          var config = {
            sectionId: this.leaseRenewalDealItems.length + this.getIndexOffset('LeaseRenewal'),
            loadInventoryOnly: false
          }
          dealItem.config = config
          this.dealItems.push(dealItem)
        }, this)

        this.currentDeal.attachments.forEach((x) => {
          x.loaded = true
          x.imageData = x.fileLocation
          x.fileType = this.getFileType(x.fileLocation)
          if (x.dealId !== 0 && x.dealItemId === 0) {
            if (x.classType === 'SaleDocument') {
              this.saleDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'CreditCard') {
              this.creditCardDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'BuyersAgreement') {
              this.buyersAgreementDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'Invoice') {
              this.invoiceDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'Other') {
              this.otherDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'Wire') {
              this.wireDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'General') {
              this.generalDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'FundingLetter') {
              this.fundingLetterDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'Square') {
              this.squareDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'Cash') {
              this.cashDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'Check') {
              this.checkDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'DealJacket') {
              this.dealJacketDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'DealJacket') {
              this.dealJacketDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'CustomerVerification') {
              this.customerVerificationDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'CdkRecap') {
              this.cdkRecapDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'DealPresentation') {
              this.dealPresentationDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'BankCreditApplicationApproval') {
              this.bankCreditApplicationApprovalDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'VehicleCost') {
              this.vehicleCostDocuments.push(x)
              this.hasDealAttachments = true
            } else if (x.classType === 'CdkWashout') {
              this.cdkWashoutDocuments.push(x)
              this.hasDealAttachments = true
            } else {
              this.otherDocuments.push(x)
              this.hasDealAttachments = true
            }
          }
        })

        this.currentDeal.supplementalCosts.forEach(function (supplementalCost) {
          this.supplementalCosts.push(supplementalCost)
        }, this)

        this.currentDeal.commissions.forEach(function (commission) {
          this.commissions.push(commission)
        }, this)

        this.selectedTags = []
        this.selectedTags = (this.currentDeal.tags && this.currentDeal.tags.length > 0) ? this.currentDeal.tags : []

        this.bookedLocked = this.currentDeal.type === 'Booked'
        this.cancelLocked = this.currentDeal.type === 'Cancelled'

        // init deal dates
        if (this.currentDeal.soldAt) {
          let localValue = moment.utc(String(this.currentDeal.soldAt)).tz(moment.defaultZone.name)
          this.currentDeal.soldAt = new Date(localValue.format('YYYY-MM-DD HH:mm'))
        }

        if (this.currentDeal.promiseDate) {
          let localValue = moment.utc(String(this.currentDeal.promiseDate)).tz(moment.defaultZone.name)
          this.currentDeal.promiseDate = new Date(localValue.format('YYYY-MM-DD HH:mm'))
        }

        if (this.currentDeal.appointmentDate && this.currentDeal.appointmentStatus !== 'Cancelled') {
          this.currentDeal.appointmentDateUtc = this.currentDeal.appointmentDate
          let localValue = moment.utc(String(this.currentDeal.appointmentDate)).tz(moment.defaultZone.name)
          this.currentDeal.appointmentDate = new Date(localValue.format('YYYY-MM-DD HH:mm'))
        }

        if (this.currentDeal.deliveryDate) {
          let deliveryDateLocalValue = moment.utc(String(this.currentDeal.deliveryDate)).tz(moment.defaultZone.name)
          this.currentDeal.deliveryDate = new Date(deliveryDateLocalValue.format('YYYY-MM-DD HH:mm'))
        }
        this.arrivalStatus = this.currentDeal.inboundStatusType !== '' ? this.definedTypes.dealInboundStatusTypes.options.find((x) => x.altName === this.currentDeal.inboundStatusType) : null
      }
    },
    clearErrors: function () {
      this.errors.clear()
      this.serverErrors = []
      this.serverErrorMessage = ''
    },
    emailCreditApplication: function () {
      if (this.currentDeal) {
        let emailAddresses = this.selectedCustomer.email
        let primaryApplicantMobile = this.selectedCustomer.mobile
        let coApplicantMobile = this.selectedCoCustomer.mobile

        var customerCreditApplicationRequestModel = {
          dealId: this.currentDeal.id,
          customerId: this.selectedCustomer.id,
          coCustomerId: this.selectedCoCustomer.id,
          userId: this.currentUser.id,
          dealerId: this.currentDeal.dealerId,
          toEmailAddress: emailAddresses,
          subject: '',
          content: '',
          type: null,
          hasCoApplicant: this.selectedCoCustomer && this.selectedCoCustomerRelationship.altName !== 'Spouse',
          primaryApplicantMobile: primaryApplicantMobile,
          coApplicantMobile: coApplicantMobile,
          creditApplicationType: 'Individual'
        }

        this.isSaving = true
        this.serverErrorMessage = ''
        this.serverErrors = []

        customerService.createCreditApplication(customerCreditApplicationRequestModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.isCreditApplicationTypeChangeDialogActive = false
            this.onComplete(this.modifiedDeal)
          }
        }).catch((error) => {
          this.isSaving = false
          this.serverErrorMessage = error.message
        })
      }
    },
    cancelEmailCreditApplication: function () {
      this.isCreditApplicationTypeChangeDialogActive = false
      this.onComplete(this.modifiedDeal)
    },
    commissionRequestStatusChange: function (selectedCommissionRequestStatus, index) {
      if (selectedCommissionRequestStatus) {
        var commission = this.commissions[index]
        if (commission) {
          commission.requestStatus = selectedCommissionRequestStatus.altName
        }
      }
    },
    reassignCreditApplication: function (resendApplication) {
      if (resendApplication) {
        this.currentDeal.resendCreditApplication = resendApplication
      }
      this.isCreditAppReassignmentActive = false
    },
    closeCreditAppAssignmentModal: function () {
      this.selectedCustomer = this.previousCreditAppCustomer
      this.isCreditAppReassignmentActive = false
    }
  },
  watch: {
    '$route.params.id': function (id) {
      this.loadDeal(id)
    },
    id: function (id) {
      this.loadDeal(id)
    },
    selectedCustomer: function (newValue, oldValue) {
      if (newValue && oldValue && (newValue.id !== oldValue.id)) {
        if (this.previousCreditAppCustomer && (newValue.id === this.previousCreditAppCustomer.id)) return
        this.previousCreditAppCustomer = oldValue
        if (this.isCreditApplicationFinalized || this.isDealTypePendingCredit) {
          this.isCreditAppReassignmentActive = true
        }
      }
    }
  },
  mounted: function () {
    var dealId = this.id ? this.id : this.$route.params.id
    if (dealId) {
      this.loadDeal(dealId)
    }
    this.AddNoteValueSection()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  a {
    color: blue;
    text-decoration: none; /* no underline */
  }
</style>
