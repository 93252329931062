<template>
    <div class="inline-block" :name="instanceId">
      <section class="inventory-commission-history-container">
        <section v-if="hasCommissionHistory && isListMode" class="is-flex">
          <i class="icon fal fa-md fa-history clickable" :class="{'is-loading': isLoading}" title="Click to view history of changes for commission field" @click="onCommissionHistoryClick"/>
        </section>
        <div :id="'inventory-commission-history-container-' + instanceId" @click="onCommissionHistoryClick" v-if="hasCommissionHistory && !isListMode">
          <i class="icon fal fa-md fa-history clickable" :class="{'is-loading': isLoading}" title="Click to view history of changes for commission field"/>
        </div>
  
        <portal to="global-modal-portal" :disabled="!usePortal" v-if="showCommissionHistoryActive">
          <b-modal :active.sync="showCommissionHistoryActive" scroll="keep" :width="640" :has-modal-card="true">
            <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                <p class="modal-card-title">Historical Purchase Commision Changes</p>
              </header>
              <section class="modal-card-body">
                <table class="table commission-table is-bordered is-narrow is-hoverable">
                  <thead>
                    <tr>
                      <td>#</td>
                      <td>Purchase Commision Value</td>
                      <td>Commission Source</td>
                      <td>Commission Request Status</td>
                      <td>Modified At</td>
                      <td>Modified By</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(historyEntry, index) in allRecordedCommissionHistory" :key="historyEntry.id" :title="historyEntry.description">
                      <td>{{index}}.</td>
                      <td>{{historyEntry.value | currency}}</td>
                      <td>{{historyEntry.commissionSource}}</td>
                      <td>{{historyEntry.commissionRequestStatus}}</td>
                      <td>{{historyEntry.createdAt | formatDate}}</td>
                      <td>{{historyEntry.createdBy}}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <footer class="modal-card-foot">
                  <button type="button" class="button is-success" @click="onCloseCommissionHistory()">Close</button>
              </footer>
            </div>
          </b-modal>
        </portal>
      </section>
    </div>
  </template>
  
  <script>
  
  import inventoryService from '@/services/inventoryService'
  
  export default {
    name: 'CommissionHistoryComponent',
    props: {
      currentInventory: {
        type: Object,
        default: null
      },
      usePortal: {
        type: Boolean,
        default: true
      },
      isListMode: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        instanceId: this.$uuid.v4(),
        showCommissionHistoryActive: false,
        allRecordedCommissionHistory: [],
        isLoading: false,
        serverErrorMessage: ''
      }
    },
    methods: {
      onCloseCommissionHistory: function () {
        this.showCommissionHistoryActive = false
        this.allRecordedCommissionHistory = []
      },
      onCommissionHistoryClick: function () {
        this.isLoading = true
        inventoryService.getCommissionHistory(this.currentInventory).then(response => {
          this.showCommissionHistoryActive = true
          this.allRecordedCommissionHistory = response || []
          this.isLoading = false
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isLoading = false
          this.allRecordedCommissionHistory = []
        })
      }
    },
    computed: {
      hasCommissionHistory: function () {
        return this.currentInventory && this.currentInventory.commissionRequests && this.currentInventory.commissionRequests.length > 0
      }
    }
  }
  
  </script>
  
  <style scoped>
    .content ul{
      list-style-type: none;
      margin-top: 0
    }
  
    .modal.is-active .modal-card-foot {
      padding: 10px !important
    }
  
    .modal-card-head, .modal-card-body{
      padding: 10px !important
    }
  
    .inventory-commission-history-container {
      display:inline-flex;
      vertical-align: middle;
    }
  
    .table {
      background-color: #f8fafc !important;
      color: #6c6c7a;
      font-size: 0.7rem;
      width: 100%;
    }
  
    .commission-table {
      background-color: #f8fafc !important;
      color: #6c6c7a;
      font-size: 0.75rem;
      width: 100%;
    }
  </style>