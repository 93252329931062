<template>
  <div class="box">
    <section-header title="Documents" :collapsed="isCollapsed"></section-header>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <div class="columns is-multiline" v-if="hasDocuments">
      <div class="column is-12" v-if="hasSaleTypeDocuments">
        <div class="field">
          <file-selector
            v-model="saleTypeDocuments"
            label="Sale Documents"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasCreditCardDocuments">
        <div class="field">
          <file-selector
            v-model="creditCardDocuments"
            label="Credit Card"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasBuyersAgreementDocuments">
        <div class="field">
          <file-selector
            v-model="buyersAgreementDocuments"
            label="Buyers Agreement"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasInvoiceDocuments">
        <div class="field">
          <file-selector
            v-model="invoiceDocuments"
            label="Invoice"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasOtherDocuments">
        <div class="field">
          <file-selector
            v-model="otherDocuments"
            label="Other"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasGeneralDocuments">
        <div class="field">
          <file-selector
            v-model="generalDocuments"
            label="General"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasWireDocuments">
        <div class="field">
          <file-selector
            v-model="wireDocuments"
            label="Wire"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasFundingLetter">
        <div class="field">
          <file-selector
            v-model="fundingLetterDocuments"
            label="Funding Letter"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasSquareDocuments">
        <div class="field">
          <file-selector
            v-model="squareDocuments"
            label="Square"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasCashDocuments">
        <div class="field">
          <file-selector
            v-model="cashDocuments"
            label="Cash"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasCheckDocuments">
        <div class="field">
          <file-selector
            v-model="checkDocuments"
            label="Check"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasDealJacketDocuments">
        <div class="field">
          <file-selector
            v-model="dealJacketDocuments"
            label="Deal Jacket"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasCustomerVerificationDocuments">
        <div class="field">
          <file-selector
            v-model="customerVerificationDocuments"
            label="Customer Verification"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasCdkRecapDocuments">
        <div class="field">
          <file-selector
            v-model="cdkRecapDocuments"
            label="CDK Recap"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasDealPresentationDocuments">
        <div class="field">
          <file-selector
            v-model="dealPresentationDocuments"
            label="Deal Presentation"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasBankCreditApplicationApprovalDocuments">
        <div class="field">
          <file-selector
            v-model="bankCreditApplicationApprovalDocuments"
            label="Bank Credit Application Approval"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasVehicleCostDocuments">
        <div class="field">
          <file-selector
            v-model="vehicleCostDocuments"
            label="Vehicle Cost"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
      <div class="column is-12" v-if="hasCdkWashoutDocuments">
        <div class="field">
          <file-selector
            v-model="cdkWashoutDocuments"
            label="CDK Washout"
            :enabled="false"
            :multiple="true"
            :showFileOnly="true"
            :isBoxed="false"
            :formatImages="true"
            :usePortalViewer="true">
          </file-selector>
        </div>
      </div>
    </div>
    <div class="columns" v-else>
      <div class="column is-size-7 has-text-info">No documents uploaded</div>
    </div>
    <quick-action-container>
      <div class="is-inline-block">
          <div class="quick-action" >
            <button type="button" class="button" @click="onUploadDocuments()" title="Click to upload documents.">
              <b-icon pack="fal" icon="cloud-upload" custom-size="fa-lg"></b-icon>
            </button>
            <view-deal-pack-sticker :currentDeal="currentDeal"></view-deal-pack-sticker>
          </div>
      </div>
    </quick-action-container>

    <portal to="global-modal-portal" :order="1" v-if="isDocumentUploadActive">
      <b-modal :active.sync="isDocumentUploadActive" scroll="keep" :has-modal-card="true" v-if="currentDeal">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Deal Documents</p>
          </header>
          <section class="modal-card-body" style="width: 960px !important; height: 65vh !important;">
            <div class="column is-12">
              <div class="field">
                <label class="label">Document Type<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control">
                  <multiselect
                      :multiple="false"
                      v-validate="{'required': true}"
                      data-vv-as="document type"
                      data-vv-validate-on="input|close"
                      :class="{'is-danger': errors.has('documentType') }"
                      name="documentType"
                      label="name"
                      track-by="name"
                      v-model="selectedDocumentType"
                      :options="availableDocumentOptions">
                    </multiselect>
                </div>
                <span v-show="errors.has('documentType')" class="help is-danger"><i v-show="errors.has('documentType')" class="fas fa-exclamation-triangle"></i> {{ errors.first('documentType') }}</span>
              </div>
            </div>
            <div class="column is-12" v-if="selectedDocumentType && selectedDocumentType.altName === 'CdkRecap'">
              <switch-selector type="is-info" label="Replace any existing documents?" v-model="replaceExisting"></switch-selector>
            </div>
            <form @submit.prevent="validateBeforeSubmit">
              <file-selector
              v-model="documentFiles"
              :label="fileUploadLabel"
              :required="true"
              :multiple="true"
              :isBoxed="false"
              :usePortalViewer="false"
              :useValidator="thisValidator"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete"
              style="margin-left: 1.3em;">
              </file-selector>
            </form>
          </section>
          <footer class="modal-card-foot">
            <button :disabled="isUploadingFile" class="button is-primary" :class="{'is-loading': isSaving }"  @click="validateBeforeSubmit()">Save</button>
            <button class="button is-danger" @click="onClose">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import SectionHeader from '@/components/generic/SectionHeader'
import QuickActionContainer from '@/components/generic/QuickActionContainer'
import dealService from '@/services/dealService'
import FileSelector from '@/components/generic/FileSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import ViewDealPackStickerAction from '@/components/sales/deals/ViewDealPackStickerAction'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import { createNamespacedHelpers } from 'vuex'
import SwitchSelector from '@/components/generic/SwitchSelector'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'DocumentsCapture',
  mixins: [utilitiesMixin, dealInventoryStyleClasses, validateBeforeSubmitMixin],
  inject: {
    $validator: '$validator'
  },
  components: {
    'file-selector': FileSelector,
    'section-header': SectionHeader,
    'quick-action-container': QuickActionContainer,
    'view-deal-pack-sticker': ViewDealPackStickerAction,
    'error-display-component': ErrorDisplayComponent,
    'switch-selector': SwitchSelector
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    },
    isCollapsed: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isSaving: false,
      isDocumentUploadActive: false,
      documentFiles: [],
      serverErrors: [],
      serverErrorMessage: '',
      isUploadingFile: false,
      fileUploadCount: 0,
      selectedDocumentType: null,
      replaceExisting: false
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    hasDocuments: function () {
      if (!this.currentDeal || !this.currentDeal.attachments) {
        return false
      }
      var primaryImages = this.currentDeal.attachments
      return primaryImages.length > 0
    },
    getDocuments: function () {
      if (!this.currentDeal || !this.currentDeal.attachments) {
        return []
      }
      var primaryImages = this.currentDeal.attachments
      var result = []
      primaryImages.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        x.fileType = this.getFileType(x.fileLocation)
        result.push(x)
      })
      return result
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    availableDocumentOptions: function () {
      let excludedTypes = ['CreditCard', 'Wire', 'Square', 'Cash', 'Check']
      let filteredTypes = this.definedTypes.dealDocumentTypes.options.filter(function (item) {
        return excludedTypes.indexOf(item.altName) === -1
      })
      return filteredTypes
    },
    fileUploadLabel: function () {
      let result = 'File'
      if (this.selectedDocumentType) {
        result = this.selectedDocumentType.name
      }
      return result
    },
    saleTypeDocuments: function () {
      if (this.hasDocuments) {
        let saleDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'SaleDocument')
        return this.formatDocuments(saleDocumentFiles)
      }
      return []
    },
    hasSaleTypeDocuments: function () {
      return this.saleTypeDocuments.length > 0
    },
    creditCardDocuments: function () {
      if (this.hasDocuments) {
        let creditCardDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'CreditCard')
        return this.formatDocuments(creditCardDocumentFiles)
      }
      return []
    },
    hasCreditCardDocuments: function () {
      return this.creditCardDocuments.length > 0
    },
    buyersAgreementDocuments: function () {
      if (this.hasDocuments) {
        let buyersAgreementDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'BuyersAgreement')
        return this.formatDocuments(buyersAgreementDocumentFiles)
      }
      return []
    },
    hasBuyersAgreementDocuments: function () {
      return this.buyersAgreementDocuments.length > 0
    },
    invoiceDocuments: function () {
      if (this.hasDocuments) {
        let invoiceDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'Invoice')
        return this.formatDocuments(invoiceDocumentFiles)
      }
      return []
    },
    hasInvoiceDocuments: function () {
      return this.invoiceDocuments.length > 0
    },
    otherDocuments: function () {
      if (this.hasDocuments) {
        let otherDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'Other')
        return this.formatDocuments(otherDocumentFiles)
      }
      return []
    },
    hasOtherDocuments: function () {
      return this.otherDocuments.length > 0
    },
    wireDocuments: function () {
      if (this.hasDocuments) {
        let wireDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'Wire')
        return this.formatDocuments(wireDocumentFiles)
      }
      return []
    },
    hasWireDocuments: function () {
      return this.wireDocuments.length > 0
    },
    generalDocuments: function () {
      if (this.hasDocuments) {
        let generalDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'General')
        return this.formatDocuments(generalDocumentFiles)
      }
      return []
    },
    hasGeneralDocuments: function () {
      return this.generalDocuments.length > 0
    },
    fundingLetterDocuments: function () {
      if (this.hasDocuments) {
        let fundingLetterDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'FundingLetter')
        return this.formatDocuments(fundingLetterDocumentFiles)
      }
      return []
    },
    hasFundingLetter: function () {
      return this.fundingLetterDocuments.length > 0
    },
    squareDocuments: function () {
      if (this.hasDocuments) {
        let squareDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'Square')
        return this.formatDocuments(squareDocumentFiles)
      }
      return []
    },
    hasSquareDocuments: function () {
      return this.squareDocuments.length > 0
    },
    cashDocuments: function () {
      if (this.hasDocuments) {
        let cashDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'Cash')
        return this.formatDocuments(cashDocumentFiles)
      }
      return []
    },
    hasCashDocuments: function () {
      return this.cashDocuments.length > 0
    },
    checkDocuments: function () {
      if (this.hasDocuments) {
        let checkDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'Check')
        return this.formatDocuments(checkDocumentFiles)
      }
      return []
    },
    hasCheckDocuments: function () {
      return this.checkDocuments.length > 0
    },
    dealJacketDocuments: function () {
      if (this.hasDocuments) {
        let dealJacketDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'DealJacket')
        return this.formatDocuments(dealJacketDocumentFiles)
      }
      return []
    },
    hasDealJacketDocuments: function () {
      return this.dealJacketDocuments.length > 0
    },
    customerVerificationDocuments: function () {
      if (this.hasDocuments) {
        let customerVerificationDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'CustomerVerification')
        return this.formatDocuments(customerVerificationDocumentFiles)
      }
      return []
    },
    hasCustomerVerificationDocuments: function () {
      return this.customerVerificationDocuments.length > 0
    },
    cdkRecapDocuments: function () {
      if (this.hasDocuments) {
        let cdkRecapDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'CdkRecap')
        return this.formatDocuments(cdkRecapDocumentFiles)
      }
      return []
    },
    hasCdkRecapDocuments: function () {
      return this.cdkRecapDocuments.length > 0
    },
    dealPresentationDocuments: function () {
      if (this.hasDocuments) {
        let dealPresentationDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'DealPresentation')
        return this.formatDocuments(dealPresentationDocumentFiles)
      }
      return []
    },
    hasDealPresentationDocuments: function () {
      return this.dealPresentationDocuments.length > 0
    },
    bankCreditApplicationApprovalDocuments: function () {
      if (this.hasDocuments) {
        let bankCreditApplicationApprovalDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'BankCreditApplicationApproval')
        return this.formatDocuments(bankCreditApplicationApprovalDocumentFiles)
      }
      return []
    },
    hasBankCreditApplicationApprovalDocuments: function () {
      return this.bankCreditApplicationApprovalDocuments.length > 0
    },
    vehicleCostDocuments: function () {
      if (this.hasDocuments) {
        let vehicleCostDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'VehicleCost')
        return this.formatDocuments(vehicleCostDocumentFiles)
      }
      return []
    },
    hasVehicleCostDocuments: function () {
      return this.vehicleCostDocuments.length > 0
    },
    cdkWashoutDocuments: function () {
      if (this.hasDocuments) {
        let cdkWashoutDocumentFiles = this.currentDeal.attachments.filter((x) => x.classType === 'CdkWashout')
        return this.formatDocuments(cdkWashoutDocumentFiles)
      }
      return []
    },
    hasCdkWashoutDocuments: function () {
      return this.cdkWashoutDocuments.length > 0
    }
  },
  methods: {
    onUploadDocuments: function () {
      this.isDocumentUploadActive = true
    },
    onClose: function () {
      this.documentFiles = []
      this.isDocumentUploadActive = false
    },
    onSave: function () {
      var updateDeal = {
        id: this.currentDeal.id,
        attachments: []
      }

      let documentType = this.selectedDocumentType.altName
      if (this.documentFiles.length > 0) {
        var uploadedDocument = this.documentFiles.map((x) => {
          return {
            id: 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            status: x.status,
            type: x.type,
            classType: documentType
          }
        })
        updateDeal.attachments.push(...uploadedDocument)
      }

      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      updateDeal.replaceExistingDocument = this.replaceExisting
      let that = this
      dealService.uploadDocuments(updateDeal).then(response => {
        that.isSaving = false
        if (response.errors) {
          that.serverErrors = response.errors
        } else {
          that.successToast('Success! uploaded successfully')
          that.documentFiles = []
          eventBus.$emit('deal-documents-uploaded', response.deal)
          that.resetModal()
          that.isDocumentUploadActive = false
        }
      }).catch((error) => {
        that.failedToast('Oops! Something went wrong')
        that.serverErrorMessage = error.message
        that.isSaving = false
      })
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    },
    resetModal: function () {
      this.uploadDocuments = false
      this.selectedDocumentType = null
      this.replaceExisting = false
    },
    formatDocuments: function (documents) {
      var result = []
      documents.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        x.fileType = this.getFileType(x.fileLocation)
        result.push(x)
      })
      return result
    }
  }
}
</script>

<style scoped>
</style>
