<template>
<div class="quick-action is-inline-block">
  <button class="button" type="button" @click="modalActive=true" :disabled="!isPickupLocationSet" :title="getBtnTitle">
    <b-icon pack="fal" icon="fal fa-location-circle" custom-size="fa-lg"></b-icon>
  </button>

  <portal to="global-modal-portal" v-if="modalActive" :order="1">
    <b-modal :active.sync="modalActive" :width="450" scroll="keep" :has-modal-card="true" :canCancel="false">
      <div class="modal-card" style="width: 450px">
        <header class="modal-card-head">
            <p class="modal-card-title">Send Pickup Location</p>
        </header>
        <section class="modal-card-body view-container">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>         
          <button-list-selector validateAs="send via" label="Send Via" :required="false" v-model="selectedSendType" :availableValues="this.definedTypes.defaultSendTypes.options" :allowDeselection="false"></button-list-selector>    
          <section v-show="showSmsForm">
            <form data-vv-scope="queue-sms-form" class="view-container">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Mobile<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <input name="mobile" class="is-width-400" data-vv-as="mobile" v-validate="'required|min:16|max:16'" scope="queue-sms-form" :class="{'input': true, 'is-danger': errors.has('queue-sms-form.mobile') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="mobile" data-lpignore="true" autocomplete="off">
                      <span v-show="errors.has('queue-sms-form.mobile')" class="help is-danger"><i v-show="errors.has('queue-sms-form.mobile')" class="fas fa-exclamation-triangle"></i> {{ errors.first('queue-sms-form.mobile') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-mobile-alt"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Message<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control">
                      <textarea rows="7" name="messageSms" v-validate="'required'" scope="queue-sms-form" :class="{'textarea': true}" data-vv-as="message" v-model="messageSms"></textarea>
                      <span v-show="errors.has('queue-sms-form.messageSms')" class="help is-danger"><i v-show="errors.has('queue-sms-form.messageSms')" class="fas fa-exclamation-triangle"></i> {{ errors.first('queue-sms-form.messageSms') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>

          <section v-show="showEmailForm">
            <form data-vv-scope="queue-email-form" class="view-container">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Recipients<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <multiselect
                      :multiple="true"
                      v-validate="{'required': true}"
                      data-vv-as="recipients"
                      data-vv-validate-on="input|close"
                      :class="{'is-danger': errors.has('queue-email-form.recipients') }"
                      @tag="onRecipientAddedEmail"
                      name="recipients"
                      label="fullNameEmail"
                      trackBy="email"
                      v-model="selectedRecipients"
                      :options="recipients"
                      :allowEmpty="false"
                      :taggable="true"
                      tagPlaceholder="Enter to add a new recipient email address"
                      data-vv-scope="queue-email-form"
                      @search-change="onSearch"
                      :searchable="true"
                      placeholder="Type to search"
                      :loading="isLoading"
                      :internal-search="false"
                      :options-limit="300">
                    </multiselect>
                    <span v-show="errors.has('queue-email-form.recipients')" class="help is-danger"><i v-show="errors.has('queue-email-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('queue-email-form.recipients') }}</span>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Message<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control">
                      <textarea rows="7" name="messageEmail" v-validate="'required'" scope="queue-email-form" :class="{'textarea': true}" data-vv-as="message" v-model="messageEmail"></textarea>
                      <span v-show="errors.has('queue-email-form.messageEmail')" class="help is-danger"><i v-show="errors.has('queue-email-form.messageEmail')" class="fas fa-exclamation-triangle"></i> {{ errors.first('queue-email-form.messageEmail') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </section>
        <footer class="modal-card-foot">
            <button v-if="showSmsForm" class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmQueueSms()" type="button">Send Sms</button>
            <button v-if="showEmailForm" class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmQueueEmail()" type="button">Send Email</button>
            <button class="button is-danger" @click="onCancel()" type="button">Cancel</button>
          </footer>
      </div>
    </b-modal>
  </portal>
</div>
</template>
<script>

import notificationService from '@/services/notificationService'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import utilitiesMixin from '@/mixins/generic/utilities'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import customerService from '@/services/customerService'
import supplierService from '@/services/supplierService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import _ from 'lodash'
import { createNamespacedHelpers } from 'vuex'

const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'SendPickupLocationControl',
  mixins: [humanizedSwitchMixin, validateBeforeSubmitMixin, utilitiesMixin],
  components: {
    'button-list-selector': ButtonListSelector,
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    deal: {
      type: Object,
      default: null
    },
    inventory: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      modalActive: false,
      isLoading: false,
      isFullPage: true,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      messageSms: '',
      messageEmail: '',
      mobile: '',
      selectedSendType: { id: 0, name: 'sms' },
      selectedRecipients: [],
      recipients: []
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    isPickupLocationSet: function () {
      if (this.inventory && this.inventory.pickupLocation === '') {
        return false
      } else if (this.inventory && this.inventory.pickupLocation) {
        return true
      }
      return false
    },
    getBtnTitle: function () {
      return this.isPickupLocationSet ? 'Click to send pickup location.' : 'Inventory pickup location must be set.'
    },
    buildPickupMessage: function () {
      if (this.inventory) {
        return 'The vehicle ' + this.inventory.name + ' is ready for pickup at the following location: ' + this.inventory.pickupLocation
      }
      return ''
    },
    isSaleTypeOfRetail: function () {
      if (this.deal && this.deal.saleType === 'Retail') {
        return true
      } else {
        return false
      }
    },
    showSmsForm: function () {
      return this.selectedSendType && this.selectedSendType.id === this.definedTypes.defaultSendTypes.sms
    },
    showEmailForm: function () {
      return this.selectedSendType && this.selectedSendType.id === this.definedTypes.defaultSendTypes.email
    }
  },
  methods: {
    onConfirmQueueSms: function () {
      this.$validator.validateAll('queue-sms-form').then((result) => {
        if (result) {
          // need to set tracking number, first check on user then dealer. We use this with InteractiveTel sms gateway
          let trackingNumber = (this.currentUser.activeTrackingNumber && this.currentUser.activeTrackingNumber !== '') ? this.currentUser.activeTrackingNumber : this.currentDealer.defaultCallTrackingNumber

          var smsNotificationModel = {
            subscriberId: this.currentDealer.subscriberId,
            dealerId: this.currentDealer.id,
            mobileNo: this.mobile,
            customerId: (this.deal && this.isSaleTypeOfRetail) ? this.deal.purchaserId : null,
            content: this.messageSms,
            supplierId: (this.deal && !this.isSaleTypeOfRetail) ? this.deal.purchaserId : null,
            callTrackingNumber: trackingNumber,
            smsType: 'PickupLocation',
            dealId: (this.deal) ? this.deal.id : null,
            appointmentDate: null
          }

          this.isSaving = true
          notificationService.queueSms(smsNotificationModel).then(response => {
            this.isSaving = false
            if (response.errors) {
              this.failedToast('Oops! Something went wrong')
              this.serverErrors = response.errors
            } else {
              this.successToast('Success! sms queued')
              this.modalActive = false
              this.setDefaults()
            }
          }).catch((error) => {
            this.failedToast('Oops! Something went wrong')
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      })
    },
    clearErrors: function () {
      this.errors.clear()
      this.serverErrors = []
      this.serverErrorMessage = ''
    },
    clearAllRecipients: function () {
      this.selectedRecipients = []
      this.recipients = []
    },
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data.fullName,
        mobile: data.mobile,
        id: data.id,
        email: data.email,
        fullNameEmail: data.fullName + ' - ' + data.email
      }
      this.selectedRecipients.push(recipient)
    },
    onRecipientAddedEmail: function (data) {
      var recipient = {
        fullName: '',
        mobile: '',
        id: 0,
        email: data,
        fullNameEmail: data
      }
      this.selectedRecipients.push(recipient)
    },
    onCancel: function () {
      this.isSaving = false
      this.modalActive = false
      this.clearErrors()
      this.setDefaults()
    },
    onConfirmQueueEmail: function () {
      this.$validator.validateAll('queue-email-form').then((result) => {
        if (result) {
          let postModel = {
            recipients: this.selectedRecipients,
            message: this.messageEmail,
            inventoryModel: this.inventory
          }
          this.isSaving = true
          notificationService.queuePickupLocationEmail(postModel).then(response => {
            this.isSaving = false
            if (response.errors) {
              this.failedToast('Oops! Something went wrong')
              this.serverErrors = response.errors
            } else {
              this.successToast('Success! email queued')
              this.modalActive = false
              this.setDefaults()
            }
          }).catch((error) => {
            this.failedToast('Oops! Something went wrong')
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      })
    },
    defaultRetailCustomers: function () {
      // make the customer or supplier that is attached to this deal selected by default
      if (this.deal && this.deal.purchaserContactEmail !== '') {
        let proposedName = this.deal.purchaserContactName !== '' ? this.deal.purchaserContactName : this.deal.purchaserName
        var data = {
          fullName: proposedName,
          mobile: this.mobile,
          id: this.deal.purchaserId,
          email: this.deal.purchaserContactEmail,
          fullNameEmail: proposedName + ' - ' + this.deal.purchaserContactEmail
        }
        this.onRecipientAdded(data)
      }
    },
    defaultWholesaleCustomers: function () {
      // make the customer or supplier that is attached to this deal selected by default
      if (this.deal && this.deal.purchaserContactEmail !== '') {
        let proposedName = this.deal.purchaserContactName !== '' ? this.deal.purchaserContactName : this.deal.purchaserName
        var data = {
          fullName: proposedName,
          mobile: this.mobile,
          id: this.deal.purchaserId,
          email: this.deal.purchaserContactEmail,
          fullNameEmail: proposedName + ' - ' + this.deal.purchaserContactEmail
        }
        this.onRecipientAdded(data)
      }
    },
    loadCustomerData: function () {
      this.clearAllRecipients()
      this.isWholesale = this.deal.saleType === 'Wholesale'
      if (this.deal && this.deal.saleType === 'Retail') {
        this.defaultRetailCustomers()
      } else if (this.deal) {
        this.defaultWholesaleCustomers()
      }
    },
    setDefaults: function () {
      this.messageSms = this.buildPickupMessage
      this.messageEmail = this.buildPickupMessage
      this.mobile = (this.deal) ? this.deal.purchaserContactMobile : ''
      if (this.deal) {
        this.loadCustomerData()
      }
    },
    loadSupplierAsyncData (query) {
      var searchCommand = {
        perPage: 32767,
        sortField: 'name',
        sortOrder: 'asc',
        defaultSortOrder: 'asc',
        tabIndex: 0,
        showIncomplete: false,
        showInactive: false,
        showBlocked: false,
        query: query,
        page: 1
      }
      this.isLoading = true
      this.recipients = []
      supplierService.findAllForNotification(searchCommand).then(response => {
        response.results.forEach((item) => {
          if (this.recipients.indexOf(item.displayName) < 0) {
            var recipient = {
              fullName: item.primaryContact,
              mobile: item.primaryContactMobile,
              id: item.id,
              email: item.email,
              fullNameEmail: item.primaryContact + ' - ' + item.email + ' ' + item.primaryContactMobile
            }
            this.recipients.push(recipient)
          }
        })
        this.isLoading = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
      })
    },
    doSupplierSearch: _.debounce(function (query) { this.loadSupplierAsyncData(query) }, 500),
    doCustomerSearch: _.debounce(function (query) { this.loadCustomerAsyncData(query) }, 500),
    onSearch (query) {
      if (query !== '') {
        if (this.deal.saleType === 'Retail') {
          this.doCustomerSearch(query)
        } else {
          this.doSupplierSearch(query)
        }
      }
    },
    loadCustomerAsyncData (query) {
      var searchCommand = {
        perPage: 32767,
        sortField: 'name',
        sortOrder: 'asc',
        defaultSortOrder: 'asc',
        tabIndex: 0,
        showIncomplete: false,
        showInactive: false,
        showBlocked: false,
        query: query,
        page: 1,
        dealerId: this.$store.state.dealer.currentDealer.id
      }
      this.isLoading = true
      this.recipients = []

      customerService.findAllByDealerIdForNotification(searchCommand).then(response => {
        response.results.forEach((item) => {
          if (this.recipients.indexOf(item.displayName) < 0) {
            var recipient = {
              fullName: item.displayName,
              mobile: item.mobile,
              id: item.id,
              email: item.email,
              fullNameEmail: item.displayName + ' - ' + item.email + '  ' + item.mobile
            }
            this.recipients.push(recipient)
          }
        })
        this.isLoading = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
      })
    }
  },
  watch: {
  },
  mounted: function () {
    this.setDefaults()
  }
}

</script>

<style scoped>

</style>
